import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './../src/locales/translation_en.json';
import translationFR from './../src/locales/translation_fr.json';
import translationDE from './../src/locales/translation_de.json';
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    de: {
    translation: translationDE
}
};
i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    //  {t('learn_more')}: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    //  {t('learn_more')}: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: resources,
        fallbackLng: 'en',
        debug: false,
        detection:{
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            lookupFromSubdomainIndex: 0,
            caches: ['localStorage', 'cookie'],
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

        },

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
