import React from 'react'
import './JoinUs.css'
import {Link} from 'react-router-dom'
import { t } from 'i18next'

import {useTranslation} from "react-i18next";
function JoinUs2(props) {
    return (
        <div className=''>
            <Link to="jobs" >
                <div className="mb-3 mb-lg-0">
                    <div className=" flex-wrap" style={{display: 'flex',justifyContent:'center'}}>
                        <div className="mr-2 mb-2">
                            <span className="button button-arrow d-inline-flex align-items-center w-auto" >
                                {t('joinUs')}
                               </span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default JoinUs2
