import React, {Component, useState, useCallback} from 'react';
import HeaderTransparent from "../CoreComponent/HeaderTransparent";
import BackgroundSectionAndText from "../Components/BackgroundSectionAndText";
import Portfolio from "../Components/Portfolio";
import Statistic from "../Components/Statistic";
import Introduction from "../Components/Introduction";
import TalkToUsSection from "../Components/TalkToUsSection";
import WhyChooseUsSection from "../Components/WhyChooseUsSection";
import Services from "../Components/Services";
import Technologies from "../Components/Technologies";
import Footer from "../Components/Footer";
import {useTranslation} from "react-i18next";
import {Step, Stepper} from "react-form-stepper";
import {sendFormUrl} from "../route";
import contactImage from './../images/yvan_axel.png';
import loader from './../images/loader.gif';
import confeti from './../images/confet.gif';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';


export default function Home() {
    const [visible, setVisible] = useState(false);
    const [requestError, setRequestError] = useState('');
    const [sendingData, setSendingData] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [errorForm, setErrorForm] = useState({name:'',email:'',phone:'',message:''});
    const { t,i18n } = useTranslation();
    const [formData, setFormData] = useState({name:'',email:'',phone:{value:''},message:''});
    const validEmailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const validPhoneRegex = /^\d+$/;
    const updateFormDataField = (field,value) =>{
        if(formData.hasOwnProperty(field)){
            if(field === 'phone'){
                let code = value.country.dialCode;
                let l = value.value.length;
                let ph = value.value.substr(code.length,l-code.length);
                setFormData({
                    ...formData,
                    [field]: {phone:ph,code:code,value:value.value}
                });
            }else{
                setFormData({
                    ...formData,
                    [field]: value
                });
            }


        }
    }
    const handleClick = (direction) => {
        let newStep = activeStep;
        direction === "next" ? newStep++ : newStep--;

        if(newStep > 0 && newStep <= 4){
            if(newStep > activeStep){
                let err = false;
                let error = {};
                switch (activeStep){
                    case 1:
                        error = {name:''};
                        if(formData.name.length===0){
                            error.name = 'red';
                            err =true;
                        }

                        if(err){
                            setErrorForm({
                                ...errorForm,
                                name: 'red'
                            });

                        }else{
                            setErrorForm({
                                ...errorForm,
                                name: ''
                            });
                            setActiveStep(newStep);
                        }
                        break;
                    case 2:
                        error = {email:'',phone:''};

                        if(!validEmailRegex.test(formData.email)){
                            error.email = 'red';

                        }
                        if(!validPhoneRegex.test(formData.phone.value) || formData.phone.value.length<7 ){
                            error.phone = 'red';
                        }



                        if(error.email.length>0 | error.phone.length>0){
                            setErrorForm({
                                ...errorForm,
                                phone: error.phone,
                                email: error.email,
                            });
                        }else{
                            setErrorForm({
                                ...errorForm,
                                phone: '',
                                email: '',
                            });
                            setActiveStep(newStep);
                        }




                        break;
                    case 3:
                        error = {message: ''};
                        if(formData.message.length===0){
                            error.message = 'red';
                            err =true;
                            setErrorForm({
                                ...errorForm,
                                message: 'red',
                            });
                        }else{
                            setErrorForm({
                                ...errorForm,
                                message: '',
                            });
                            setActiveStep(newStep);
                        }

                        break;
                    default:
                        break;
                }
            }
            else{
                setActiveStep(newStep);
            }

        }
    };

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setVisible(true)
        }
        else if (scrolled <= 300){
            setVisible(false)
        }
    };

    const sendMessage = () => {
        if(formData.message.length===0){
            setErrorForm({
                ...errorForm,
                message: 'red',
            });
        }else{
            setErrorForm({
                ...errorForm,
                message: '',
            });
            console.log(formData);
            setSendingData(true);
            fetch(sendFormUrl, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(formData),
            }).then((res) => {
                if(res.status === 201){
                    setActiveStep(4);
                    setSendingData(false);
                    setTimeout(()=>{
                        window.location.reload(false);
                    },10000);

                }
                else{
                    setSendingData(false);
                    res.json().then((err)=>{
                        console.log(err);
                        setRequestError(err.message.message.toString());
                        setTimeout(()=>{
                            setRequestError('');
                        },10000);
                    });

                }

            }).catch((error) => {
                setSendingData(false);
                console.log(error)
            });
        }



    }

    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);
    return ( <div>
        <HeaderTransparent/>
        <main className="main">
            <BackgroundSectionAndText/>
            <Services/>
            <Statistic/>
            <WhyChooseUsSection/>
            <Portfolio/>
            <TalkToUsSection/>
            <Introduction/>
            <Technologies/>
            <section id="contact" className="section section form--gray py-6 mt-3">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-14 col-xl-13" style={{backgroundImage: activeStep === 4?`url(${confeti})`:''}}>
                            <div style={{background: 'transparent'}} className="card card--contact">
                                <div className="mb-2 mb-xl-3">
                                    <h2 data-aos="fade-right">{t('what_can_we_do_for_you')}</h2>
                                    <p data-aos="fade-right">{t('what_can_we_do_for_you_undertitle')}</p>
                                </div>
                                <div>
                                    <div className="wpcf7 no-js" id="wpcf7-f4365-p3205-o1" lang="en-US" dir="ltr">
                                        <div className="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true" /> <ul /></div>

                                        <div   className="wpcf7-form init wpcf7-acceptance-as-validation div-form"  >
                                            <div className='row'>
                                                <Stepper connectorStateColors={true}
                                                         activeStep={activeStep-1}
                                                         connectorStyleConfig={{ size: 2,activeColor: '#152B45',completedColor:'#f9b122' }}
                                                         styleConfig={{ size: 50 }}  style={{width: '100%'}}>
                                                    <Step styleConfig={{labelFontSize: 14,completedBgColor:'#f9b122',size: 50,activeBgColor: '#152B45'}}  completed={activeStep>1} index={0} label={t('form_item_name')} />
                                                    <Step styleConfig={{labelFontSize: 14,completedBgColor:'#f9b122',size: 50,activeBgColor: '#152B45'}} completed={activeStep>2} index={1} label={t('form_item_email')+' / '+t('form_item_phone')} />
                                                    <Step styleConfig={{labelFontSize: 14,completedBgColor:'#f9b122',size: 50,activeBgColor: '#152B45'}} completed={activeStep > 3} index={2} label={t('form_item_message')} />
                                                    <Step styleConfig={{labelFontSize: 14,completedBgColor:'#f9b122',size: 50,activeBgColor: '#152B45'}} completed={activeStep=== 4} index={3} label={t('congratulation')} />
                                                </Stepper>

                                            </div>
                                            <div className="row" style={{display: activeStep === 1?'block':'none'}} >
                                                <div className="col-24" data-aos="fade-right" data-aos-delay={100}>
                                                    <div className="form__input">
                                                                <span className="wpcf7-form-control-wrap" data-name="your-name">
                                                                    <input onChange={(e)=>updateFormDataField('name',e.target.value)} value={formData.name} style={{borderColor: errorForm.name.length === 0?'#b2b2b2':'red'}} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required floating" autoComplete="off" aria-required="true" aria-invalid="false" type="text" name="your-name" /></span>
                                                        <label className="form__label" htmlFor="your-name">{t('form_item_name')} *</label>
                                                        <span className="wpcf7-not-valid-tip" style={{display: errorForm.name.length > 0?'block':'none'}}
                                                              aria-hidden="true">{t('required_field')}</span>
                                                    </div>
                                                </div>
                                                <div className="col-24">
                                                    <button onClick={()=>handleClick('next')} className="button btn-makiti float-right">{t('go')}</button>

                                                </div>
                                            </div>
                                            <div style={{display: activeStep === 2?'block':'none'}} className="row" data-aos="fade-right" data-aos-delay={200}>
                                                <div className="col-md-24">
                                                    <div className="form__input">
                                                                <span className="wpcf7-form-control-wrap" data-name="your-email">
                                                                    <input onChange={(e)=>{updateFormDataField('email',e.target.value)}} value={formData.email} style={{borderColor: errorForm.email.length === 0?'#b2b2b2':'red'}} size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email floating" autoComplete="off" aria-required="true" aria-invalid="false" type="email" name="your-email" /></span>
                                                        <label className="form__label" htmlFor="your-email">{t('form_item_email')} *</label>
                                                        <span className="wpcf7-not-valid-tip" style={{display: errorForm.email.length > 0?'block':'none'}}
                                                              aria-hidden="true">{t('invalid_email')}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-24">

                                                    <div className="form__input">
                                                        <span className="wpcf7-form-control-wrap" data-name="your-tel">
                                                        <PhoneInput
                                                            country={'cm'}
                                                            value={formData.phone.value}
                                                            inputClass="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel floating"
                                                            placeholder={t('form_item_phone')+ ' *'}
                                                            onChange={(value,country, event,formattedValue)=>{updateFormDataField('phone',{value:value,country:country,format:formattedValue})}}


                                                            inputStyle={{
                                                                borderColor: errorForm.phone.length === 0?'#b2b2b2':'red'
                                                            }}
                                                            inputProps={{
                                                                name: 'phone',
                                                                required: true,
                                                                autoFocus: true
                                                            }}
                                                        />
                                                        </span>
                                                        <br/>
                                                        <span className="wpcf7-not-valid-tip" style={{display: errorForm.phone.length > 0?'block':'none'}}
                                                              aria-hidden="true">{t('phone_error')}</span>
                                                    </div>
                                                </div>
                                                <div className="col-24">
                                                    <button onClick={()=>handleClick('next')} className="button btn-makiti float-right">{t('go')}</button>
                                                    <button onClick={()=>handleClick('back')} className="button btn-makiti float-left">{t('back')}</button>

                                                </div>
                                            </div>
                                            <div style={{display: activeStep === 3?'block':'none'}} className="row">
                                                <div className="col-24" data-aos="fade-right" data-aos-delay={300}>
                                                    <div className="form__textarea">
                                                                <span className="wpcf7-form-control-wrap" data-name="your-message">
                                                                    <textarea onChange={(e)=>{updateFormDataField('message',e.target.value)}} value={formData.message} style={{paddingTop: 30, borderColor: errorForm.message.length === 0?'#b2b2b2':'red'}} cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required floating" aria-required="true" aria-invalid="false" name="your-message"  /></span>
                                                        <label className="form__label" htmlFor="your-message">{t('form_item_message')} *</label>
                                                        <span className="wpcf7-not-valid-tip" style={{display: errorForm.message.length > 0?'block':'none'}}
                                                              aria-hidden="true">{t('required_field')}</span>
                                                    </div>
                                                    <p style={{fontSize: 14,color: 'red', minHeight: 100,display: requestError!==undefined&& requestError.length===0?'none':'block'}}>
                                                        {requestError}
                                                    </p>
                                                </div>
                                                <div className="col-24">
                                                    <button disabled={sendingData}  onClick={()=>sendMessage()} className="button btn-makiti float-right"> {t('send')}
                                                        <img style={{width:15,display: sendingData ===true?'inline':'none'}} src={loader} alt=""/></button>
                                                    <button style={{display: sendingData ===false?'block':'none'}} disabled={sendingData} onClick={()=>handleClick('back')} className="button btn-makiti float-left">{t('back')}</button>

                                                </div>
                                            </div>

                                            <div style={{display: activeStep === 4 && errorForm.email.length===0 && errorForm.name.length===0 && errorForm.phone.length===0 && errorForm.message.length===0 ?'block':'none'}} className="row mt-3" data-aos="fade-right" data-aos-delay={600}>

                                                <div className="col-24 aos-init aos-animate" data-aos="fade-right">
                                                    <h2 className="p-typ__title font-weight-semibold" style={{marginBottom: '28px',fontSize: 24}}>{t('success_message')}</h2>


                                                </div>

                                            </div>
                                            <div className="wpcf7-response-output" aria-hidden="true" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 text-center mt-3 mt-md-0 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2">
                            <div className="form__image" data-aos="fade-left" data-aos-delay={200}>
                                <img width={514} height={497} src={contactImage} className="attachment-full size-full" alt="" loading="lazy" />
                            </div>
                            <div className="form__signature py-1" data-aos="fade-left" data-aos-delay={400}>
                                <div className="font-weight-semibold font-size-m">
                                    Ivan Axel
                                </div>
                                <div className="font-weight-semibold font-size-s">
                                    {t('project_analyst')}
                                </div>
                            </div>
                            <div className="mt-1" data-aos="fade-left" data-aos-delay={400}>
                                <div className="form__email d-flex align-items-center justify-content-center">
                                    <div className="form__icon mr-1">
                                        <svg viewBox="0 0 16 12">
                                            <path fill="#f9b020" d="M15.7 3.96a.19.19 0 0 1 .3.15v6.39a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 10.5V4.11a.19.19 0 0 1 .3-.14C1 4.5 1.93 5.2 5.12 7.52 5.78 8 6.89 9 8 9c1.12 0 2.25-1.02 2.88-1.49 3.2-2.31 4.12-3 4.82-3.55ZM8 8c.72.01 1.77-.91 2.3-1.3 4.14-3 4.46-3.27 5.41-4.02A.75.75 0 0 0 16 2.1v-.6A1.5 1.5 0 0 0 14.5 0h-13A1.5 1.5 0 0 0 0 1.5v.6a.75.75 0 0 0 .29.58c.95.75 1.27 1.02 5.42 4.03C6.23 7.09 7.28 8 8 8Z" />
                                        </svg>
                                    </div>
                                    <div className="font-weight-semibold">
                                        <a href="mailto:contact@makiti.de">contact@makiti.de</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <button onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}} type="button" className="back-top-btn js-back-top is-active">
            <div className="back-top-btn__arrow d-flex justify-content-center align-items-center">
                <svg viewBox="0 0 20 11.44"><defs /><path d="M10 3.45L2.43 11a1.42 1.42 0 01-2 0 1.44 1.44 0 010-2L9 .42a1.44 1.44 0 012 0L19.58 9a1.43 1.43 0 11-2 2z" data-name="Icon ionic-ios-arrow-forward" /></svg>
            </div>
        </button>
        <Footer/>
    </div> );
}
