import React from 'react'
import './JoinUs.css'
import {Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";

function JoinUs(props) {
    const { t,i18n } = useTranslation();
    return (
        <div className=''>
            <Link to="apply" state={{ job: props.job }}>
                <div className="mb-3 mb-lg-2">
                    <div className=" flex-wrap" style={{display: 'flex',justifyContent:'center'}}>
                        <div className="mr-2 mb-2">
                            <span style={{fontSize:'1.5rem'}} className="button button-arrow d-inline-flex align-items-center w-auto" >
                                 {t('soummetez_votre_candidature')}
                               </span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default JoinUs
