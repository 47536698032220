import React from "react";
import javascriptImage from './../images/makiti/Services_icon/javascript.png';
import adobeXdImage from './../images/makiti/Services_icon/adobe-xd.png';
import adobeCreativeImage from './../images/makiti/Services_icon/adobecreativecloud.png';
import angularImage from './../images/makiti/Services_icon/angular.png';
import dockerImage from './../images/makiti/Services_icon/docker.png';
import intellijImage from './../images/makiti/Services_icon/intellij.png';
import javaImage from './../images/makiti/Services_icon/java.png';
import laravelImage from './../images/makiti/Services_icon/laravel.png';
import mongodbImage from './../images/makiti/Services_icon/mongodb.png';
import nginxImage from './../images/makiti/Services_icon/nginx.png';
import nodeJsImage from './../images/makiti/Services_icon/nodejs.png';
import phpImage from './../images/makiti/Services_icon/php.png';
import reactImage from './../images/makiti/Services_icon/react.png';
import springImage from './../images/makiti/Services_icon/spring.png';
import symfonyImage from './../images/makiti/Services_icon/symfony.png';
import wordpressImage from './../images/makiti/Services_icon/wordpress.png';
import vscodeImage from './../images/makiti/Services_icon/vs-code.png';
import jsonWebTokenImage from './../images/makiti/Services_icon/jsonwebtokens.png';
import elementorImage from './../images/makiti/Services_icon/elementor.png';
import oauthImage from './../images/makiti/Services_icon/oauth.png';
import mysqlImage from './../images/makiti/Services_icon/mysql.png';
import oracleImage from './../images/makiti/Services_icon/oracle.png';
import pubSubImage from './../images/makiti/Services_icon/pub-sub.png';
import openshiftImage from './../images/makiti/Services_icon/openshift.png';
import googleCloudImage from './../images/makiti/Services_icon/Google_Cloud_logo.png';
import apacheKafkaImage from './../images/makiti/Services_icon/apachekafka.png';
import wooCommerceImage from './../images/makiti/Services_icon/woocommerce.png';
import powerBiImage from './../images/makiti/Services_icon/powerbi.png';
import pythonImage from './../images/makiti/Services_icon/python.png';
import kubernatesImage from './../images/makiti/Services_icon/kubernetes.png';



import BackgroundTechnology from './../images/Bg.png';
import {useTranslation} from "react-i18next";
export default function Technologies(){
    const { t } = useTranslation();
    return (<> <section className="section-margin slider-technologies js-particle" data-aos="fade-up" data-aos-duration="2000">
        <div className="js-slider-technologies overflow-hidden d-flex align-items-center justify-content-center">
            <div className="swiper-wrapper">
                <div className="swiper-slide slider-technologies__item item-tech " >
                    <img alt="" src={javascriptImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={angularImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={javaImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={nodeJsImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={springImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={reactImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={phpImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={symfonyImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={laravelImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={mongodbImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={nginxImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={adobeXdImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={adobeCreativeImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={dockerImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={intellijImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={wordpressImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={vscodeImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={jsonWebTokenImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={elementorImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={oauthImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={mysqlImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={oracleImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={pubSubImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={openshiftImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={googleCloudImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={apacheKafkaImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={wooCommerceImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={powerBiImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={pythonImage} />
                </div>
                <div className="swiper-slide slider-technologies__item item-tech ">
                    <img alt="" src={kubernatesImage} />
                </div>

            </div>
        </div>
    </section>
        <section className="mobile-technologies " data-aos="fade-up" style={{backgroundImage: `url(${BackgroundTechnology})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 text-center text-lg-left">
                        <h2>Technology</h2>
                    </div>
                    <div className="col-lg-16">
                        <p className="mobile-technologies__text mobile-technologies__text--big mt-3 mt-lg-1 mb-0">
                            {t('technology_section')}  </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-24">
                        <div className="row mobile-technologies__list-wrapper mt-3 mt-xl-5">
                            <div className="col-sm-8 mb-3 mb-xl-5 col-lg-8 ">
                                <h3>{t('technology_frontend')}</h3>
                                <ul >
                                    <li>
                                        <span  >
                                            Angular
                                        </span>
                                    </li>
                                    <li>
                                        <span  >
                                            React.js
                                        </span>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-sm-8 mb-3 mb-xl-5 col-lg-8 ">
                                <h3>{t('technology_backend')}</h3>
                                <ul >
                                    <li>
                                        <span  >
                                            Java Spring Boot
                                        </span>
                                    </li>
                                    <li>
                                        <span  >
                                            PHP Symfony
                                        </span>
                                    </li>
                                    <li>
                                        <span  >
                                            Node JS
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-8 mb-3 mb-xl-5 col-lg-8 ">
                                <h3>{t('technology_database')}</h3>
                                <ul >
                                    <li><span>PostgreSQL</span></li>
                                    <li><span>MongoDB</span></li>
                                    <li><span>Oracle</span></li>
                                </ul>
                            </div>
                            <div className="col-sm-8 mb-3 mb-xl-5 col-lg-8 ">
                                <h3>{t('technology_organization')}</h3>
                                <ul >
                                    <li><span>M365</span></li>
                                    <li><span>Postman</span></li>
                                    <li><span>Jira/Confluence</span></li>
                                </ul>
                            </div>
                            <div className="col-sm-8 mb-3 mb-xl-5 col-lg-8 ">
                                <h3>{t('technology_testing_2')}</h3>
                                <ul >
                                    <li><span>JUnit/Jest</span></li>
                                    <li><span>Cypress/Selenium</span></li>
                                    <li><span>Appium</span></li>
                                </ul>
                            </div>
                            <div className="col-sm-8 mb-3 mb-xl-5 col-lg-8 ">
                                <h3>{t('technology_cms')}</h3>
                                <ul >
                                    <li><span>Wordpress</span></li>
                                    <li><span>WooCommerce</span></li>
                                    <li><span>Symfony Sonata</span></li>
                                    <li><span>Laravel Backpack</span></li>
                                </ul>
                            </div>

                            <div className="col-sm-8 mb-3 mb-xl-5 col-lg-8 ">
                                <h3>{t('technology_cross_platform')}</h3>
                                <ul >
                                    <li>
                                        <span>
                                            React Native
                                        </span>
                                    </li>
                                    <li><span>Flutter/Dart</span></li>
                                    <li><span>Xamarin</span></li>
                                </ul>
                            </div>
                            <div className="col-sm-8 mb-3 mb-xl-5 col-lg-8 ">
                                <h3>{t('technology_native')}</h3>
                                <ul >
                                    <li>
                                        <span>
                                            Swift
                                        </span>
                                    </li>
                                    <li><span>Kotlin</span></li>
                                </ul>
                            </div>

                            <div className="col-sm-8 mb-3 mb-xl-5 col-lg-8 ">
                                <h3>{t('technology_deployment')}</h3>
                                <ul >
                                    <li>
                                        <span  >
                                            Docker
                                        </span>
                                    </li>
                                    <li><span>Kubernetes</span></li>
                                    <li><span>Openshift</span></li>
                                    <li><span>Nginx</span></li>
                                </ul>
                            </div>
                            <div className="col-sm-8 mb-3 mb-xl-5 col-lg-8 ">
                                <h3>{t('technology_cloud')}</h3>
                                <ul >
                                    <li>
                                        <span  >
                                            AWS
                                        </span>
                                    </li>
                                    <li><span>GCP</span></li>
                                    <li><span>Azure</span></li>
                                </ul>
                            </div>
                            <div className="col-sm-8 mb-3 mb-xl-5 col-lg-8 ">
                                <h3>{t('technology_ui')}</h3>
                                <ul >
                                    <li>
                                        <span  >
                                            Adobe Creative Cloud
                                        </span>
                                    </li>
                                    <li><span>Figma</span></li>
                                    <li><span>Behance</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}







