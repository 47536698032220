import React from "react";
import logo from "../images/logo_mkt.png";
import facebook from './../images/makiti/social_media_logo/Facebook_3D_logo.png';
import instagram from './../images/makiti/social_media_logo/Instagram_3D_logo.png';
import twitter from './../images/makiti/social_media_logo/Twitter_3D_logo.png';
import linkedin from './../images/makiti/social_media_logo/Linkedin_3D_logo.png';
import xing from './../images/makiti/social_media_logo/xing.png';
import whatsapp from './../images/makiti/social_media_logo/WA_3D.png';
import './footer.css';
import {useTranslation} from "react-i18next";
export default function Footer(){
    const { t } = useTranslation();
    return (<>
        <footer className="footer js-footer" style={{color: '#fff'}}>
            <div className="footer__wrapper js-footer-wrapper">
                <div className="container ">
                    <div className='row ' style={{borderBottomColor: '#3C3C3C', borderBottomStyle: 'solid', borderBottomWidth: '1'}}>
                    </div>
                    <div className='row' >
                        <div className='col-24 pb-2'>
                            <h4 className='mt-2'>{t('contact')}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-16 order-lg-last col-xxl-17">
                        </div>
                        <div className="col-lg-8 col-xxl-7 mb-2">
                            <div className="row">
                                <a className="footer__logo d-none d-lg-block" href="/">
                                    <img width={168} height={72} src={logo} alt="Makiti Group" />
                                </a>
                            </div>
                            <div className="row">
                                <div className="footer__socials d-flex align-items-center">
                                    <a className="footer__icon" href="https://web.facebook.com/profile.php?id=100089224489061&mibextid=LQQJ4d&_rdc=1&_rdr" target="_blank" rel="nofollow">
                                        <img width={20} height={20} src={facebook}  alt="Facebook" />
                                    </a>
                                    <a className="footer__icon" href="https://www.instagram.com/makiti_group/" target="_blank" rel="nofollow">
                                        <img width={20} height={20} src={instagram}  alt="Instagram" />
                                    </a>
                                    <a className="footer__icon" href="https://twitter.com/MakitiGroup" target="_blank" rel="nofollow">
                                        <img width={20} height={20} src={twitter}  alt="Twitter" />
                                    </a>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="footer__socials d-flex align-items-center">
                                    <a className="footer__icon" href="https://www.linkedin.com/company/makiti-group/about/" target="_blank" rel="nofollow">
                                        <img width={20} height={20} src={linkedin}  alt="Linkedin" />
                                    </a>
                                    <a className="footer__icon" href="https://www.xing.com/pages/makiti" target="_blank" rel="nofollow">
                                        <img width={20} height={20} src={xing}  alt="Xing" />
                                    </a>
                                    <a className="footer__icon" href="https://api.whatsapp.com/send?phone698922533=&text=Hi" target="_blank" rel="nofollow">
                                        <img width={20} height={20} src={whatsapp}  alt="Whatsapp" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-16 col-xxl-17" >
                            <div className='row'>
                            </div>
                            <div className='row'>
                                <div  className="col-lg-12 col-xxl-12 col-md-12" >
                                    <h4>{t('country_cameroon')}</h4>
                                    <div className='mt-2'>
                                    <h5 className='mb-1'><span className="companyName">{t('makiti_sarl')}</span></h5>
                                        <h5 className='mb-1'><span className='bi bi-house-fill '></span> B.P. 1011 {t('city_yaounde')}</h5>
                                        <h5 className='mb-1'><span className='bi bi-phone-fill '></span> <a style={{color: '#fff'}} href="tel:+237653788053">+237 6 53 78 80 53</a></h5>
                                        <h5 className='mb-1'><span className='bi bi-whatsapp '></span> <a style={{color: '#fff'}} href="https://wa.me/237698922533?text=Hi%20I%20would%20like%20to%20get%20in%20touch%20with%20you">+237 6 98 92 25 33</a></h5>
                                        <h5 className='mb-1'><span className='bi bi-geo-fill '></span> Santa Barbara - face Sonel</h5>
                                        <h5 className='mb-1'><span className='bi bi-envelope-at-fill '></span> contact@makiti.de</h5>
                                        <h5 className='mb-1'><span className='bi bi-map-fill '></span>  <a style={{color: '#fff'}} href="https://maps.app.goo.gl/EPDXU8UEwC5hwFWs6" target="_blank">Get Directions</a></h5>
                                    </div>
                                    <br />
                                </div>
                                <div className="col-lg-12 col-xxl-12 col-md-12" >
                                    <h4>{t('country_france')}</h4>
                                    <div className='mt-2'>
                                    <h5 className='mb-1'><span className="companyName">{t('makiti_group_sarl')}</span></h5>
                                        <h5 className='mb-1'><span className='bi bi-house-fill '></span> 75008 {t('city_paris')}</h5>
                                        <h5 className='mb-1'><span className='bi bi-phone-fill '></span> <a style={{color: '#fff'}} href="tel: +33757690289">+33 6 84 08 36 84</a></h5>
                                        <h5 className='mb-1'><span className='bi bi-whatsapp '></span> <a style={{color: '#fff'}} href="https://wa.me/491627265788?text=Hi%20I%20would%20like%20to%20get%20in%20touch%20with%20you">+49 162 72 65 788</a></h5>
                                        
                                        <h5 className='mb-1'><span className='bi bi-geo-fill '></span> CS 48756, Cedex 08, 75380  <br/> <br/>
58 rue de Monceau</h5>
                                        <h5 className='mb-1'><span className='bi bi-mailbox2 '></span> {t('city_precision')}</h5>
                                        <h5 className='mb-1'><span className='bi bi-envelope-at-fill '></span> contact@makiti.de</h5>
                                        <h5 className='mb-1'><span className='bi bi-map-fill '></span>  <a style={{color: '#fff'}} href="https://www.google.com/maps/dir//58+Rue+de+Monceau,+Paris/@48.878289,2.3070511,16z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x47e66fb8223c18ed:0x1144769557471933!2m2!1d2.3122117!2d48.8782821?entry=ttu" target="_blank">Get Directions</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer__copyright"><div className="d-md-flex justify-content-between">
                        <button className="button text-white d-none">Terms of use</button>
                        <p style={{color: '#fff'}}>©  Makiti Group  All rights reserved</p>
                    </div></div>
                </div>
                <div className="row">
                </div>
            </div>
        </footer></>)
}
