import React, {Component, useCallback} from 'react';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import PartnersIcon from "./PartnersIcon";
import headerImg from "./../images/developer_activity.png";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export default function BackgroundSectionAndText(){
    const { t,i18n } = useTranslation();

    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
    }, []);

    return ( <section className="header-home js-parallax">
            <Particles height="1050px" style={{position: 'absolute'}}
                       width="100%" id="sc-h"  options={{
                smooth: true,
                background: {
                    color: {
                        value: "#000",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    detect_on: "canvas",
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: false,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: false,
                    },
                    move: {
                        directions: "up",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 0.5,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 200,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "square",
                    },
                    size: {
                        value: { min: 1, max: 3 },
                    },
                },
                detectRetina: true,
                fullScreen: {enable: false,zIndex:0},
            }} init={particlesInit} loaded={particlesLoaded} />
            <div className="header-home__content">
                <div className="container">
                    <div className="row align-items-center align-items-xxl-end out-row">
                        <div className="col-lg-12 col-xl-14 col out-row__col py-3 py-lg-4 pb-xl-5" data-aos="fade-right" data-aos-duration="2000">
                            <h1 className="header-home__title  font-weight-semibold mb-1 text-center text-sm-left">{t('main_title')}</h1>
                            <p className="header-home__subtitle text-white font-weight-light lh-13 text-center text-sm-left">{t('main_sub_title')}</p>
                            <div className="row mt-3">

                                <div className="d-none col-md-12 col-lg-24 col-xl-11 col-xxl-auto">
                                    <Link to="/software-development" className="button button-arrow button-arrow--darkBg d-flex align-items-center justify-content-center mb-2 font-size-s-xl font-size-n-xxl button-arrow--outline" >
                                        {t('service_software')}
                                       </Link>
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-20 col-lg-12 col-xl-10 out-row__col out-row__col--out-46 mt-2 mt-lg-0 pt-5" data-aos="fade-left" data-aos-duration="2000">
                            <img width={1657} height={1092} src={headerImg} className="w-100 h-100 obj-contain scale-10-p" alt="" />
                        </div>
                    </div>
                    <div className="col-24 " data-aos="fade-right">
                        <h4 style={{fontSize: 16,color:'grey', textAlign: 'center',marginTop: 40}}>{t('we_have_worked_there')}</h4>
                    </div>

                    <PartnersIcon/>
                </div>
            </div>
        </section>
    );
}
