import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import HeaderWithBackground from "../CoreComponent/HeaderWithBackground";
import {useTranslation} from "react-i18next";
import Img1 from "../Img/clock_dark.webp";
import Img2 from "../Img/video.webp";
import Img3 from "../Img/bike_dark.webp";
import './congratulation.css';

export default function Congratulation(){
    const { t } = useTranslation();
    const[time,setTime]=useState(30);
    const[start,setStart]=useState(true);
    const sliderImages = [Img1,Img2,Img3];
    //const[isFetching,setIsFetching]=useState(true);

    /*useEffect(() => {
        const interval = setInterval(() => {
            if(time>0){
                setTime(time => time - 1);
                if(time === 1){

                    window.history.pushState({}, undefined, "/");
                    window.location.reload();
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);*/





    return(<>
        <div className="w-screen ">
            <HeaderWithBackground background={'white'} title="Jobs" />
        </div>
        <section style={{marginTop: 200,background:'#f5f3f1',minHeight:540,paddingTop:40}} className="section--alt" >
            <div className="" style={{padding:24}}>
                <h2 style={{textAlign:"center"}} className="is-heading-h2 mb-4">
                    {" "}
                    The recruitment <span>process</span>
                </h2>
                <div className=" d-lg-block d-md-none d-none">
                    <div className="row" >
                        <div className="col-lg-8 col-md-8 ">
                            <div className="recruitment_process text-center">
                                <div className="recruitment_process__circle" style={{backgroundColor:"#C1DADE"}}>
                                    <img loading="lazy"
                                         src={Img1}/>
                                </div>
                                <p> First things first, we need to know how you’d like to work. So, we’ll need some documents
                                    from you and then there will be a phone interview. </p>
                            </div>

                        </div>
                        <div className="col-lg-8 col-md-8 ">
                            <div className="recruitment_process text-center">
                                <div className="recruitment_process__circle" style={{backgroundColor:"#F6C243"}}>
                                    <img loading="lazy"
                                         src={Img2}/>
                                </div>
                                <p> We’ll contact you about the work contract. After signing the contract you’ll learn about the tools you’ll use and booking your first shift. </p>

                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 ">
                            <div className="recruitment_process text-center">
                                <div className="recruitment_process__circle" style={{backgroundColor:"#F2A6B0"}}>
                                    <img loading="lazy"
                                         src={Img3}/>
                                </div>
                                <p> On your first day the courier captain will support you and explain everything you need to know to complete your very first order.</p>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="row d-md-block d-sm-block  d-lg-none  " style={{minHeight: 450}}>
                    <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                        <SwiperSlide>
                            <div className="recruitment_process text-center">
                                <div className="recruitment_process__circle" style={{backgroundColor:"#C1DADE"}}>
                                    <img loading="lazy"
                                         src={Img1}/>
                                </div>
                                <p> First things first, we need to know how you’d like to work. So, we’ll need some documents
                                    from you and then there will be a phone interview. </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="recruitment_process text-center">
                                <div className="recruitment_process__circle" style={{backgroundColor:"#F6C243"}}>
                                    <img loading="lazy"
                                         src={Img2}/>
                                </div>
                                <p> We’ll contact you about the work contract. After signing the contract you’ll learn about the tools you’ll use and booking your first shift. </p>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="recruitment_process text-center">
                                <div className="recruitment_process__circle" style={{backgroundColor:"#F2A6B0"}}>
                                    <img loading="lazy"
                                         src={Img3}/>
                                </div>
                                <p> On your first day the courier captain will support you and explain everything you need to know to complete your very first order.</p>

                            </div>
                        </SwiperSlide>

                    </Swiper>

                </div>
            </div>
        </section>
    </>);

}
