import React, {useEffect, useRef, useState} from 'react'
import JobItem from "./JobItem";
import loader from './../images/loader.gif';
import {listJobs} from "../route";
import {useTranslation} from "react-i18next";

function Content(props) {
    const [jobs,setJobs] = useState([]);
    const [sendingData,setSendingData] = useState(true);
    const dataFetchedRef = useRef(false);
    const { t,i18n } = useTranslation();

    useEffect(()=>{
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchData();
    },[]);


    const fetchData = () => {
        if(sendingData && jobs.length===0) {

            fetch(listJobs, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((res) => {

                if (res.status === 200) {
                    setSendingData(false);
                    res.json().then((e) => {
                        setJobs(e.rows);
                    });
                } else {
                    setSendingData(false);
                    res.json().then((e) => {
                        console.log(e);
                    });
                }

            }).catch((err) => {

                setSendingData(false);
                console.log(err);
            });
        }
    };



    return (
        <div className="container" style={{marginTop: 70, display: 'flex', justifyContent:'center'}}>
            <div className=" px-4 py-6 sm:px-0">
                <div className="bg-white ">
                    {jobs.length>0 && sendingData === false && jobs.map((f,i)=>(<JobItem key={i} item={f}/>))}
                    {jobs.length === 0 && sendingData === false &&(<div style={{height:600}}>{t('no_jobs_available_now')}</div>)}
                    {sendingData&&(<div style={{marginTop:100,minHeight:800}}> <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={224}
                        height={224}
                        preserveAspectRatio="xMidYMid"
                        style={{
                            margin: "auto",
                            background: "transparent",
                            display: "block",
                            shapeRendering: "auto",
                        }}
                        viewBox="0 0 100 100"
                    >
                        <circle cx={50} cy={23} r={13} fill="#f8b020">
                            <animate
                                attributeName="cy"
                                calcMode="spline"
                                dur="0.8695652173913042s"
                                keySplines="0.45 0 0.9 0.55;0 0.45 0.55 0.9"
                                keyTimes="0;0.5;1"
                                repeatCount="indefinite"
                                values="23;77;23"
                            />
                        </circle>
                    </svg></div>)}
                </div>

            </div>
        </div>
    )
}

export default Content
