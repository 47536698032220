import React from "react";
export default function NumberAndTextItem(props){
    return (<div className="container">
        <div  className={props.side !==undefined && props.side==='right'?'row py-3 py-xxl-4 align-items-center normal aos-init aos-animate flex-md-row-reverse':'row py-3 py-xxl-4 align-items-center normal aos-init aos-animate'} data-aos="fade-up"  data-aos-duration="2000" data-aos-delay={300}>
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-10">
                        <div className="content-numbers__number ">
                            <h1 style={{fontSize: '10rem'}} className="font-weight-semibold  text-lg-center text-md-center text-sm-center">{props.number}</h1>
                        </div>
                    </div>
                    <div className="col-lg-14 text-lg-left text-md-center text-sm-center">
                        <h3 className="mb-2 why_choose">{props.title}</h3>
                        <p className="why_choose">{props.description}</p>

                    </div>

                </div>


                <div id="gtx-trans" style={{position: 'absolute', left: '32px', top: '-10px'}}>
                    <div className="gtx-trans-icon" />
                </div>
            </div>
            <div className="col-lg-12 text-center mt-3 mt-lg-0">
            </div>
        </div>
    </div>)
}
