import React, {Component, useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import HeaderTransparent from "../CoreComponent/HeaderTransparent";
import Portfolio from "../Components/Portfolio";
import Technologies from "../Components/Technologies";
import Footer from "../Components/Footer";
import scrum_process from "../images/SCRUM_PROCESS.jpeg";
import dev_page1 from "../Img/dev_page 1.png"
export default function SoftwareDevelopment(){
    const { t,i18n } = useTranslation();
    const [visible, setVisible] = useState(false)
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setVisible(true)
        }
        else if (scrolled <= 300){
            setVisible(false)
        }
    };
    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };
    window.addEventListener('scroll', toggleVisible);
    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {

    }, []);


    return(<div>
        <HeaderTransparent/>
        <main className="main">
            <div className="banner__bg">
                <section className="header-home ">
                    <Particles height="800px" style={{position: 'absolute'}}
                               width="100%" id="software-dev"  options={{
                        smooth: true,
                        background: {
                            color: {
                                value: "#000",
                            },
                        },
                        fpsLimit: 120,
                        interactivity: {
                            detect_on: "canvas",
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: "push",
                                },
                                onHover: {
                                    enable: true,
                                    mode: "repulse",
                                },
                                resize: true,
                            },
                            modes: {
                                push: {
                                    quantity: 4,
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: "#ffffff",
                            },
                            links: {
                                color: "#ffffff",
                                distance: 150,
                                enable: false,
                                opacity: 0.5,
                                width: 1,
                            },
                            collisions: {
                                enable: false,
                            },
                            move: {
                                directions: "up",
                                enable: true,
                                outModes: {
                                    default: "bounce",
                                },
                                random: false,
                                speed: 0.5,
                                straight: false,
                            },
                            number: {
                                density: {
                                    enable: true,
                                    area: 800,
                                },
                                value: 200,
                            },
                            opacity: {
                                value: 0.5,
                            },
                            shape: {
                                type: "square",
                            },
                            size: {
                                value: { min: 1, max: 3 },
                            },
                        },
                        detectRetina: true,
                        fullScreen: {enable: false,zIndex:0},
                    }} init={particlesInit} loaded={particlesLoaded} />
                    <div className="header-home__content">
                        <div className='container position-relative '>
                            <div className="row align-items-start align-items-lg-center ">
                                <div className="banner__data col-lg-12 pb-1">
                                    <h2 className="text-light aos-init aos-animate" data-aos="fade-right">
                                        {t('software_built_with_love')}
                                        <br />
                                        ({t('what_is_software_dev')})
                                    </h2>
                                    <p className="mb-3 font-weight-light text-custom-gray" style={{fontSize:"2rem",lineHeight:"4.8rem"}}>
                                        {t('statistic')}
                                    </p>
                                    <p
                                        className="d-none d-lg-block pb-3 pb-xl-4 mt-3 aos-init aos-animate"
                                        data-aos="fade-right"
                                        data-aos-delay={500}
                                    >
                                        <a
                                            href="/#contact"
                                            className="button button-arrow button-arrow--darkBg w-auto"
                                            title="Discover"
                                        >
                                            {t('get_in_touch')}
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.995 8">
                                                <path
                                                    data-name="Icon ionic-ios-arrow-round-forward"
                                                    d="M7.649.148a.544.544 0 0 0 0 .767l2.533 2.537H.537a.542.542 0 0 0 0 1.083h9.637L7.641 7.072a.548.548 0 0 0 0 .767.539.539 0 0 0 .762 0l3.433-3.458a.608.608 0 0 0 .112-.171.517.517 0 0 0 .042-.208.543.543 0 0 0-.154-.379L8.403.165a.531.531 0 0 0-.754-.017Z"
                                                />
                                            </svg>
                                        </a>
                                    </p>
                                </div>

                                <div className="banner__image col-lg-12 ml-auto pt-2 pt-lg-0 align-self-center pt-xl-2 pb-2 pb-xl-5">
                                    <img
                                        className="aos-init aos-animate"
                                        data-aos="fade-left"
                                        data-aos-delay={1000}
                                        src={dev_page1}
                                        alt=""
                                        width={753}
                                        height={540}
                                    />
                                </div>
                                <p className="d-block d-lg-none text-center text-lg-left w-100 mt-3 pb-4">
                                    <a
                                        href="#form"
                                        className="button button-arrow button-arrow--darkBg w-auto"
                                        title="Discover"
                                    >
                                        {t('lets_talk')}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.995 8">
                                            <path
                                                data-name="Icon ionic-ios-arrow-round-forward"
                                                d="M7.649.148a.544.544 0 0 0 0 .767l2.533 2.537H.537a.542.542 0 0 0 0 1.083h9.637L7.641 7.072a.548.548 0 0 0 0 .767.539.539 0 0 0 .762 0l3.433-3.458a.608.608 0 0 0 .112-.171.517.517 0 0 0 .042-.208.543.543 0 0 0-.154-.379L8.403.165a.531.531 0 0 0-.754-.017Z"
                                            />
                                        </svg>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>


                </section>
            </div>


            <section className="section-margin mb-0 aos-init aos-animate" data-aos="fade-up">
                <div className="container">
                    <div className="content">
                        <h2><strong>  {t('software_development_process')} </strong></h2>
                        <p>{t('software_development_process_desc')}</p>

                    </div>
                </div>
            </section>

            <div className=" container-fluid">
                <div className="  px-1 mx-n2 mx-sm-0 ">
                    <div className="container-fluid px-xxl-5 ">

                        <div className="tabs mt-3 aos-init aos-animate" data-aos="fade-up">
                            <div className="tab js-tab is-active" data-tab-id={0}>
                                <div className="row align-items-center">
                                    <div className="col-lg-14 col-xl-13">
                                        <div className="mt-2 my-md-0">
                                            <div className="d-sm-none text-center">
                                                <img width={385} height={835} loading="lazy" className="maxw-100 h-auto obj-contain" src={scrum_process} alt="Develtio process" />
                                            </div>
                                            <div className="d-none d-sm-block">
                                                <img width={966} height={452} loading="lazy" className="w-100 h-100 obj-contain" src={scrum_process} alt="Develtio process" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-9 offset-lg-1 col-xxl-9 offset-xxl-2 d-none d-lg-block">
                                        <div className="d-block text-center ">

                                            <p className="   text-justify px-1 px-sm-0" >
                                                {t('software_dev_process_desc')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Portfolio/>
            <Technologies/>

        </main>
        <button onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}} type="button" className="back-top-btn js-back-top is-active">
            <div className="back-top-btn__arrow d-flex justify-content-center align-items-center">
                <svg viewBox="0 0 20 11.44"><defs /><path d="M10 3.45L2.43 11a1.42 1.42 0 01-2 0 1.44 1.44 0 010-2L9 .42a1.44 1.44 0 012 0L19.58 9a1.43 1.43 0 11-2 2z" data-name="Icon ionic-ios-arrow-forward" /></svg>
            </div>
        </button>
        <Footer/>
    </div>)
}
