import React  from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import './styles/main7daa.css';
import Home from './Pages/Home'
import ErrorPage from "./Pages/ErrorPage";
import i18n from './i18n';
import SoftwareDevelopment from "./Pages/SoftwareDevelopment";
import Jobs from "./Pages/Jobs";
import FullStackDev from "./Pages/FullStackDev";
import WebRedactor from "./Pages/WebRedactor";
import AngularDev from "./Pages/AngularDev";
import JobSubmit from "./Pages/JobSubmit";
import AboutUs from './Pages/AboutUs';
import NewsletterActivation from "./Pages/NewsletterActivation";
import NewsletterDesactivation from "./Pages/NewsletterDesactivation";
import JobDetail from "./Pages/JobDetail";
import Congratulation from "./Pages/Congratulation";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/software-development",
        element: <SoftwareDevelopment/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/jobs",
        element: <Jobs/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/jobs/fullstack-developer",
        element: <FullStackDev/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/jobs/web-redactor",
        element: <WebRedactor/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/jobs/angular-developer",
        element: <AngularDev/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/aboutus",
        element: <AboutUs/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/aboutus/jobs",
        element: <Jobs/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/act/:service/:id",
        element: <NewsletterActivation/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/des/:service/:id",
        element: <NewsletterDesactivation/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/jobs/m/:title/:id",
        element: <JobDetail/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/jobs/m/:title/:id/apply",
        element: <JobSubmit/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/jobs/congratulation",
        element: <Congratulation/>,
        errorElement: <ErrorPage />,
    },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <React.StrictMode>
          <RouterProvider router={router} />
      </React.StrictMode>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.  {t('learn_more')}: https://bit.ly/CRA-vitals
//reportWebVitals();
