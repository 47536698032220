import React, {Component, useRef, useState,useEffect} from 'react';
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

function JobItem(props) {
    const { t,i18n } = useTranslation();
    const [item, setItem] = useState(props.item!==undefined?props.item:{});
    const slugify = str =>
        str
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '');
    const [itemToDisplay, setItemToDisplay] = useState(props.item!==undefined?{"title":props.item.jobTitle,"description":props.item.jobShortDescription,"url":'job_details/'+slugify(props.item.jobTitle)+'/'+props.item._id}:{"title":"","description":"","url":""});
    function changeBackground(e) {
        e.target.style.background = '#F9B021';
    }
    function changeBackground2(e) {
        e.target.style.background = '';
    }



    function displayItemByLanguage() {
        switch(i18n.resolvedLanguage) {
            case 'fr':
                setItemToDisplay({"title":props.item.jobTitle,"description":props.item.jobShortDescription,"url":'m/'+slugify(props.item.jobTitle)+'/'+props.item._id})
                break;
            case 'en':
                setItemToDisplay({"title":props.item.jobTitle,"description":props.item.jobShortDescription,"url":'m/'+slugify(props.item.jobTitle)+'/'+props.item._id})
                break;
            case 'de':
                setItemToDisplay({"title":props.item.jobTitle,"description":props.item.jobShortDescription,"url":'m/'+slugify(props.item.jobTitle)+'/'+props.item._id})
                break;
        }
    }

    useEffect (()=>{
        displayItemByLanguage();
    },[i18n.resolvedLanguage])



    return (
        <Link to={itemToDisplay.url}><div style={{maxWidth: 800}} className=" row  mb-3 mb-xl-3">
            <div className="hov section-reviews__box  p-2 p-xl-3 pb-xl-2 mx-xxl-1" >
                <div className="d-flex align-items-center">
                    <h2 style={{fontSize:'2em'}} className=" mb-1 mt-1 mt-lg-0">{itemToDisplay.title}</h2>
                </div>
                <p className="font-size-m mt-1">{itemToDisplay.description}</p>
            </div>
        </div>
        </Link>
    )
}
export default JobItem
