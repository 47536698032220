import React from "react";
import openIcon from "../Img/enlarge.svg";

export default function PortfolioItem(props){
    return (<div className="thumb-work ">
        <div>
            <div className="thumb-work__image">
                <img width={674} height={492} src={props.imageMini} className="attachment-large size-large" alt="" loading="lazy" />
            </div>
            <div className="thumb-work__enlarge" data-modal-open={props.modal}>
                <img src={openIcon} alt="enlarge" width={24} height={24} />
            </div>
        </div>
        <div className="thumb-work__content">
            <a href="#" data-modal-open={props.modal}>
                <h4 className="thumb-work__title mb-1">{props.title}</h4>
            </a>
            <p className="thumb-work__description text-custom-gray mb-2 font-weight-light">{props.description}</p>
            {props.technologies.map((item) => (<a key={props.modal+Math.floor(Math.random()*101).toString()} href="#" className="thumb-work__categories bg--grey100 text-custom-gray">{item}</a>))}
            <div className="thumb-work__link">
                <a href="#"  data-modal-open={props.modal}>
                    {props.btnText}
                </a>
            </div>
        </div>
    </div>);
}
