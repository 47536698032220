import React, {useEffect, useRef, useState} from "react";
import HeaderTransparent from "../CoreComponent/HeaderTransparent";
import Content from "../Components/Content";
import Footer from "../Components/Footer";
import HeaderWithBackground from "../CoreComponent/HeaderWithBackground";
import {listJobs} from "../route";

function Jobs(props) {
    const scrollToTop = () =>{
        window.scrollTo({
            top: 30,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };
    scrollToTop();

    return (
        <div id="career">

            <div className="w-screen ">
                <HeaderWithBackground background={'white'} title="Jobs" />
            </div>
            <main>
                <div className=" ">
                    <div className="mx-auto max-w-3xl py-2 sm:px-6 lg:px-8 mb-8">
                        <Content />
                    </div>
                </div>
            </main>
            <Footer style={{fontSize: 14, position: props.position ===undefined?'fixed':'relative',width:'100%',bottom: 0}} />
        </div>
    );
}

export default Jobs;
