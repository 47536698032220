import React, {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import "../Img/Form1.svg"
import "../Img/Form2.svg"
import "../Img/Form3.svg"
import Img1 from "../Img/step1.png";
import Img2 from "../Img/step2.png";
import Img3 from "../Img/step3.png";
import Img4 from "../Img/step4.png";
import moment from "moment";
import Footer from "./Footer";
import {useTranslation} from "react-i18next";
import {uploadUrl,applyUrl} from "../route";
import HeaderWithBackground from "../CoreComponent/HeaderWithBackground";
import confeti from "../images/confet.gif";
import {Step, Stepper} from "react-form-stepper";
import loader from "../images/loader.gif";
import Upload from "./Upload";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CurrencyInput from 'react-currency-input-field';


function Form(props) {

    const location = useLocation();
    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };



    const [visible, setVisible] = useState(false);
    const [requestError, setRequestError] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [errorForm, setErrorForm] = useState({name:'',email:'',phone:'',message:'',birthdate:'',city:'',location:'',files:'',date:'',engagementType:'',salaryExpectation:'',computerDetail:'',expectedStartingDate:'',workingDates:'',workLocation:''});
    const { t,i18n } = useTranslation();
    const [formData, setFormData] = useState(
        {jobId:props.id, jobTitle:location.state.job.jobTitle,name:'',
            birthdate:'',email:'',country:'CM',street:'',phone:{value:''},location:'',city:'',
            files:[],engagementType:'',salaryExpectation:'',computerDetail:{availability:false,detail:''},expectedStartingDate:'',workingDates:'',workLocation:'',
        });
    const [sendingData,setSendingData] = useState(false);
    const [dayList,setDayList] = useState(
        [
        {day:1,name:t('day_1'),startHour:'08:00',endHour:'17:00',type:'on site',checked:false,selectedType:'',isEditableLocation:true,isEditableDay: true,isEditableTime:true},
        {day:2,name:t('day_2'),startHour:'08:00',endHour:'17:00',type:'on site',checked:false,selectedType:'',isEditableLocation:true,isEditableDay: true,isEditableTime:true},
        {day:3,name:t('day_3'),startHour:'08:00',endHour:'17:00',type:'on site',checked:false,selectedType:'',isEditableLocation:true,isEditableDay: true,isEditableTime:true},
        {day:4,name:t('day_4'),startHour:'08:00',endHour:'17:00',type:'on site',checked:false,selectedType:'',isEditableLocation:true,isEditableDay: true,isEditableTime:true},
        {day:5,name:t('day_5'),startHour:'08:00',endHour:'17:00',type:'on site',checked:false,selectedType:'',isEditableLocation:true,isEditableDay: true,isEditableTime:true},
        {day:6,name:t('day_6'),startHour:'08:00',endHour:'17:00',type:'on site',checked:false,selectedType:'',isEditableLocation:true,isEditableDay: true,isEditableTime:true},
        {day:7,name:t('day_7'),startHour:'08:00',endHour:'17:00',type:'on site',checked:false,selectedType:'',isEditableLocation:true,isEditableDay: true,isEditableTime:true},
    ]);
    const [selectedDays,setSelectedDays]=useState(
        [
            dayList[0],
            dayList[1],
            dayList[2],
            dayList[3],
            dayList[4]
        ]
    );
    const [choosenResponse, setChoosenResponse] = useState();


    const updateFormDataField = (field,value) =>{

        if(formData.hasOwnProperty(field)){
            if(field === 'country'){
                setFormData({
                    ...formData,
                    country: value,city:''

                });
            }else if(field === 'phone'){

                let code = value.country.dialCode;
                let l = value.value.length;
                let ph = value.value.substr(code.length,l-code.length);
                setFormData({
                    ...formData,
                    [field]: {phone:ph,code:code,value:value.value}
                });

            }
            else
            {
                setFormData({
                    ...formData,
                    [field]: value
                });


            }

        }
        else{
            if(field === 'computerDetailAvailability'){

                setChoosenResponse(value)

                setFormData({
                    ...formData,
                    ['computerDetail']: {availability:value,detail:formData.computerDetail.detail}
                });

            }
            else if(field === 'computerDetailInfo'){
                setFormData({
                    ...formData,
                    ['computerDetail']: {availability:formData.computerDetail.availability,detail:value}
                });

            }else{

            }
        }
    }

    const [userData, setUserData] = useState("");
    const [finalData, setFinalData] = useState([]);
    const [countries,setCountries] = useState([
        {id: 1,label: 'Cameroon', value: 'CM'}
    ]);
    const [country, setCountry] = useState(countries[0]);
    const [totalHour, setTotalHour] = useState(0);
    const [city, setCity] = useState('');
    const [allNeighborhood, setAllNeighborhood] = useState({
        yaounde:[
            {
                "name": "Abom"
            },
            {
                "name": "Afanoya 1"
            },
            {
                "name": "Afanoya 2"
            },
            {
                "name": "Afanoya 3"
            },
            {
                "name": "Afanoya 4"
            },
            {
                "name": "Ahala 1"
            },
            {
                "name": "Ahala 2"
            },
            {
                "name": "Awae"
            },
            {
                "name": "Bata Nlongkak"
            },
            {
                "name": "Biteng"
            },
            {
                "name": "Biyem-Assi"
            },
            {
                "name": "Dakar"
            },
            {
                "name": "Ecole De Police"
            },
            {
                "name": "Efoulan"
            },
            {
                "name": "Ekie"
            },
            {
                "name": "Ekoumdoum"
            },
            {
                "name": "Ekounou"
            },
            {
                "name": "Ekounou"
            },
            {
                "name": "Eleveur"
            },
            {
                "name": "Elig-Effa"
            },
            {
                "name": "Emana"
            },
            {
                "name": "Emombo"
            },
            {
                "name": "Essos"
            },
            {
                "name": "Essos"
            },
            {
                "name": "Etam-Bafia"
            },
            {
                "name": "Etetak"
            },
            {
                "name": "Etoa"
            },
            {
                "name": "Etoudi"
            },
            {
                "name": "Etoug-Ebe 1"
            },
            {
                "name": "Etoug-Ebe 2"
            },
            {
                "name": "Febe"
            },
            {
                "name": "Grand-Messa"
            },
            {
                "name": "Kondengui 1"
            },
            {
                "name": "Kondengui 2"
            },
            {
                "name": "Kondengui 3"
            },
            {
                "name": "Madagascar"
            },
            {
                "name": "Manguier"
            },
            {
                "name": "Mebandan"
            },
            {
                "name": "Mekoumbou 1"
            },
            {
                "name": "Mekoumbou 2"
            },
            {
                "name": "Melen"
            },
            {
                "name": "Melen 2 – Centre Administratif"
            },
            {
                "name": "Melen 8"
            },
            {
                "name": "Melen 8b Et C"
            },
            {
                "name": "Mendong 1"
            },
            {
                "name": "Mendong 2"
            },
            {
                "name": "Messa Carriere"
            },
            {
                "name": "Messame-Ndongo"
            },
            {
                "name": "Mfandena 1"
            },
            {
                "name": "Mfandena 2"
            },
            {
                "name": "Mimboman 1"
            },
            {
                "name": "Mimboman 3"
            },
            {
                "name": "Minkoameyos"
            },
            {
                "name": "Mokolo"
            },
            {
                "name": "Mvan-Nord"
            },
            {
                "name": "Mvan-Sud"
            },
            {
                "name": "Mvog-Ada"
            },
            {
                "name": "Mvog-Betsi"
            },
            {
                "name": "Mvog-Mbi"
            },
            {
                "name": "Ndamvout"
            },
            {
                "name": "Ngoa-Ekele 1"
            },
            {
                "name": "Ngoa-Ekele 2"
            },
            {
                "name": "Ngoulmekong"
            },
            {
                "name": "Ngousso 1"
            },
            {
                "name": "Ngousso 2"
            },
            {
                "name": "Ngousso-Ntem"
            },
            {
                "name": "Nkol-Ebogo"
            },
            {
                "name": "Nkol-Messeng"
            },
            {
                "name": "Nkol-Ndongo 1"
            },
            {
                "name": "Nkol-Ndongo 2"
            },
            {
                "name": "Nkolafeme"
            },
            {
                "name": "Nkolbikok"
            },
            {
                "name": "Nkolbisson"
            },
            {
                "name": "Nkolfon"
            },
            {
                "name": "Nkolmesseng 1"
            },
            {
                "name": "Nkolo"
            },
            {
                "name": "Nkolso"
            },
            {
                "name": "Nkom-Kana"
            },
            {
                "name": "Nkomo"
            },
            {
                "name": "Nlong Mvolye"
            },
            {
                "name": "Nsam"
            },
            {
                "name": "Nsimeyong 1"
            },
            {
                "name": "Nsimeyong 2"
            },
            {
                "name": "Nsimeyong 3"
            },
            {
                "name": "Ntouessong"
            },
            {
                "name": "Ntoungou"
            },
            {
                "name": "Ntui-Essong"
            },
            {
                "name": "Obili"
            },
            {
                "name": "Obobogo"
            },
            {
                "name": "Odza"
            },
            {
                "name": "Olezoa"
            },
            {
                "name": "Oliga"
            },
            {
                "name": "Omnisport"
            },
            {
                "name": "Oyom-Abang"
            },
            {
                "name": "Quartier Fouda"
            },
            {
                "name": "Santa barbara"
            },
            {
                "name": "Simbock"
            },
            {
                "name": "Simbock Ecole De Guerre"
            },
            {
                "name": "Titi garage"
            },
            {
                "name": "Tsinga"
            }
        ],
        douala:[
            {
                name: "Akwa"
            },
            {
                name: "Base Elf"
            },
            {
                name: "Base Navale"
            },
            {
                name: "Bassa"
            },
            {
                name: "Bassa Z.I"
            },
            {
                name: "Bassa-Ccc"
            },
            {
                name: "Bependa"
            },
            {
                name: "Bependa Axe-Lourd"
            },
            {
                name: "Bependa-Omnisport"
            },
            {
                name: "Bonaberi"
            },
            {
                name: "Bonaberi-Bekoko"
            },
            {
                name: "Bonaberi-Bojongo"
            },
            {
                name: "Bonaberi-Bonassama"
            },
            {
                name: "Bonaberi-Mabanda"
            },
            {
                name: "Bonaberi-Sodiko"
            },
            {
                name: "Bonadibong"
            },
            {
                name: "Bonamoussadi"
            },
            {
                name: "Bonanjo"
            },
            {
                name: "Bonapriso"
            },
            {
                name: "Borne 10"
            },
            {
                name: "Brazzaville"
            },
            {
                name: "Camp Yabassi"
            },
            {
                name: "Cite-Cicam"
            },
            {
                name: "Cite-Palmiers"
            },
            {
                name: "Cite-Sic"
            },
            {
                name: "Dakar"
            },
            {
                name: "Deido"
            },
            {
                name: "Deido Bessengue & New Deido"
            },
            {
                name: "Essec"
            },
            {
                name: "Ghana"
            },
            {
                name: "Japoma"
            },
            {
                name: "Kenya"
            },
            {
                name: "Km5-Terminus"
            },
            {
                name: "Kotto"
            },
            {
                name: "Kribi"
            },
            {
                name: "Log-Pom"
            },
            {
                name: "Logbaba"
            },
            {
                name: "Logbessou"
            },
            {
                name: "Madagascar"
            },
            {
                name: "Makepe"
            },
            {
                name: "Makepe-Logpom"
            },
            {
                name: "Makepe-Missoke"
            },
            {
                name: "Marche Congo"
            },
            {
                name: "Mbangue"
            },
            {
                name: "Mbanya&Sable"
            },
            {
                name: "Mboppi"
            },
            {
                name: "Namibia"
            },
            {
                name: "Ndogbong"
            },
            {
                name: "Ndogpassi I"
            },
            {
                name: "Ndogpassi Ii"
            },
            {
                name: "Ndogpassi Iii"
            },
            {
                name: "Ndogsimbi"
            },
            {
                name: "Ndokotti"
            },
            {
                name: "New-Bell"
            },
            {
                name: "Nkongmondo"
            },
            {
                name: "Nkoulouloun"
            },
            {
                name: "Nyalla"
            },
            {
                name: "Pk10"
            },
            {
                name: "Pk11"
            },
            {
                name: "Pk12"
            },
            {
                name: "Pk13"
            },
            {
                name: "Pk14"
            },
            {
                name: "Pk15"
            },
            {
                name: "Pk16"
            },
            {
                name: "Pk17"
            },
            {
                name: "Pk18"
            },
            {
                name: "Pk19"
            },
            {
                name: "Pk23"
            },
            {
                name: "Pk25"
            },
            {
                name: "Pk8"
            },
            {
                name: "Pk9"
            },
            {
                name: "Rond-Point Deido"
            },
            {
                name: "Village"
            },
            {
                name: "Yassa"
            },
            {
                name: "Youpwe"
            }
        ],
        bafoussam:[
            {
                name:  "Akwa"
            },
            {
                name:  "Badiontong",
            },
            {
                name:  "Bakajou",
            },
            {
                name:  "Bakelac",
            },
            {
                name:  "Bakeule",
            },
            {
                name:  "Balaafi",
            },
            {
                name:  "Balaveng",
            },
            {
                name:  "Baleng 1",
            },
            {
                name:  "Baleng 2",
            },
            {
                name:  "Bamendzi",
            },
            {
                name:  "Bamougoum",
            },
            {
                name:  "Bandeng",
            },
            {
                name:  "Banefo",
            },
            {
                name:  "Banengo",
            },
            {
                name:  "Bapi",
            },
            {
                name:  "Batoukap",
            },
            {
                name:  "Baye",
            },
            {
                name:  "Bazegam",
            },
            {
                name:  "Demsiem",
            },
            {
                name:  "Djassa",
            },
            {
                name:  "Djeleng 1",
            },
            {
                name:  "Djeleng 2",
            },
            {
                name:  "Doupe",
            },
            {
                name:  "Évêché",
            },
            {
                name:  "Famla",
            },
            {
                name:  "Famleng",
            },
            {
                name:  "Fampi",
            },
            {
                name:  "Famtchouet",
            },
            {
                name:  "Fou’sap",
            },
            {
                name:  "Gouache",
            },
            {
                name:  "Hélak",
            },
            {
                name:  "Houmkahaa",
            },
            {
                name:  "Kamkop",
            },
            {
                name:  "Keuleu",
            },
            {
                name:  "Kena",
            },
            {
                name:  "King-Place Bapi",
            },
            {
                name:  "Kobikong",
            },
            {
                name:  "Konti",
            },
            {
                name:  "Koptchou",
            },
            {
                name:  "Kouogouo",
            },
            {
                name:  "La’tsit",
            },
            {
                name:  "Lafé",
            },
            {
                name:  "Lagoueng",
            },
            {
                name:  "Lemgwo",
            },
            {
                name:  "Marché A ",
            },
            {
                name:  "Marché B ",
            },
            {
                name:  "Marché Casablanca ",
            },
            {
                name:  "Mbwo",
            },
            {
                name:  "Metto",
            },
            {
                name:  "Mewehee",
            },
            {
                name:  "Mfé",
            },
            {
                name:  "Ndembou Menjo",
            },
            {
                name:  "Ndennda",
            },
            {
                name:  "Ndianbou",
            },
            {
                name:  "Ndiandam",
            },
            {
                name:  "Ndianso",
            },
            {
                name:  "Ndionkou",
            },
            {
                name:  "Ndjinga",
            },
            {
                name:  "Ndjunang Metè",
            },
            {
                name:  "Ndoubé",
            },
            {
                name:  "Néfo",
            },
            {
                name:  "Néfoloum",
            },
            {
                name:  "Ngonlé",
            },
            {
                name:  "Nkajou",
            },
            {
                name:  "Nkwabang",
            },
            {
                name:  "Nylon 1",
            },
            {
                name:  "Nylon 2",
            },
            {
                name:  "Nylon 3",
            },
            {
                name:  "Nylon 4",
            },
            {
                name:  "Nylon 5",
            },
            {
                name:  "Nylon 6",
            },
            {
                name:  "Nylon 7",
            },
            {
                name:  "Nylon 8",
            },
            {
                name:  "Nylon 9",
            },
            {
                name:  "Nylon 10",
            },
            {
                name:  "Quartier Haoussa ",
            },
            {
                name:  "Sachiè",
            },
            {
                name:  "Singté",
            },
            {
                name:  "Takoutché",
            },
            {
                name:  "Tamdja",
            },
            {
                name:  "Tchanda",
            },
            {
                name:  "Tchitchap",
            },
            {
                name:  "Tchouo",
            },
            {
                name:  "Toket",
            },
            {
                name:  "Tomdjo",
            },
            {
                name:  "Tougang",
            },
            {
                name:  "Touhekououp",
            },
            {
                name:  "Touhenyee",
            },
            {
                name:  "Toungang Village",
            },
            {
                name:  "Toungang-Ville",
            },
            {
                name:  "Tyo Village",
            },
            {
                name:  "Tyo Ville",
            },
            {
                name:  "Wong",
            },
            {
                name:  "Yanmbah"
            },
        ]

    });
    const [displayedNeighborhood, setDisplayedNeighborhood] = useState([]);
    const [neighborhood, setNeighborhood] = useState('');
    const [files, setFiles] = useState([]);
    const [computerDetailPossible, setComputerDetailPossible] = useState([{'name':true,'label':t('yes')},{'name':false,'label':t('no')}]);
    const [removedItem, setRemovedItem] = useState(null);
    const [engagementList, setEngagementList] =
        useState([
            {'name':'on site',label:t('on_site')},{'name':'remote',label:t('remote')},{'name':'hybrid',label:t('hybrid')}
        ]);
    const [engagementListTab, setEngagementListTab] =
        useState([
            {'name':'on site',label:t('on_site')},{'name':'remote',label:t('remote')},
        ]);
    const [worktype, setWorktype] =
        useState('');
    const [workLocation, setWorkLocation] =
        useState('');
    const [worktypeList, setWorktypeList] =
        useState([
            {'name':'part time',label:t('part_time')},{'name':'full time',label:t('full_time')}
        ]);

    const [engagementType, setEngagementType] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);

    const [errorFormSubmit, setErrorFormSubmit] = useState(t('form_item_file_error_msg'));


    const [cities,setCities] = useState([

        {id: 1,name: 'Douala',code:'douala', country:'CM'},
        {id: 2,name: 'Yaoundé',code:'yaounde', country:'CM'},
        {id: 3,name: 'Bafoussam',code:'bafoussam', country:'CM'}
    ]);

    const [quaters,setQuaters] = useState([
    ]);
    
    useEffect(() => {
        if(!firstLoad){

            scrollToTop();
            setFirstLoad(true);
        }

    },[firstLoad]);

    const resetUploadFormError = () =>{
        setErrorForm({
            ...errorForm,
            files: ''
        });
    }


    const steps = [
        t('form_header_step_one'),
        t('form_header_step_two'),
        t('form_header_step_three'),
        t('form_header_step_four'),
        t('form_header_step_five'),
    ];
    const testDate = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    const displayStep = (step) => {

    };
    const handleBg = (step) => {

        switch (step +1) {
            case 1:
                return Img1;
            case 2:
                return Img2;
            case 3:
                return Img3;
            case 4:
                return Img3;
            case 5:
                return Img4;
            default:
                break;
        }
    };

    const validEmailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const validPhoneRegex = /^\d+$/;



    const handleClick = (direction) => {
        let newStep = activeStep;
        direction === "next" ? newStep++ : newStep--;

        if(newStep >= 0 && newStep <= 5){
            if(newStep > activeStep){
                let err = false;
                let error = {};
                switch (activeStep){
                    case 0:
                        error = {name:'',email:'',birthdate:'',phone:''};
                        if(formData.name.length===0){
                            error.name = 'red';
                            err =true;
                        }
                        if(!validEmailRegex.test(formData.email)){
                            error.email = 'red';
                            err =true;

                        }

                        if(validPhoneRegex.test(formData.phone.value)){
                            console.log(formData.phone);
                            if(formData.phone.code.toString() === "237"){
                                if(formData.phone.phone.length !== Number(9)){
                                    error.phone = 'red';
                                    err =true;
                                }
                            }else{
                                if(formData.phone.phone.length < Number(7)){
                                    error.phone = 'red';
                                    err =true;
                                }
                            }
                        }
                        else{
                            error.phone = 'red';
                            err =true;

                        }





                        if(!moment(formData.birthdate, 'YYYY-MM-DD', true).isValid()){
                            error.birthdate = 'red';
                            err =true;
                        }
                        else{
                            let age = parseInt(moment(formData.birthdate,'YYYY-MM-DD', true).diff(moment(), 'years').toString());
                            if(age > 0 || age > -18){
                                error.birthdate = 'red';
                                err =true;
                            }
                        }


                        if(err){
                            setErrorForm({
                                ...errorForm,
                                name: error.name,
                                email: error.email,
                                phone: error.phone,
                                birthdate: error.birthdate,
                            });

                        }else{
                            setErrorForm({
                                ...errorForm,
                                name: error.name,
                                email: error.email,
                                phone: error.phone,
                                birthdate: error.birthdate,
                            });
                            setActiveStep(newStep);
                        }

                        break;
                    case 1:
                        error = {country:'',city:'',location:''};
                        if(typeof(country)==='string'){
                            error.country = 'red';
                            err = true;
                        }

                        if(typeof(city)==='string'){
                            error.city = 'red';
                            err = true;
                        }

                        if(typeof(neighborhood)==='string'){
                            error.location = 'red';
                            err = true;
                        }




                        if(err){
                            setErrorForm({
                                ...errorForm,
                                country: error.country,
                                city: error.city,
                                location: error.location
                            });

                        }else{
                            setErrorForm({
                                ...errorForm,
                                country: error.country,
                                city: error.city,
                                location: error.location,
                            });
                            setActiveStep(newStep);
                        }
                        break;
                    case 2:
                        error = {engagementType:'',salaryExpectation:'',computerDetail:'',expectedStartingDate:'',workingDates:'',workLocation:''};
                        if(formData.engagementType ===''){
                            error.engagementType = 'red';
                            err = true;
                        }
                        if(formData.workLocation ===''){
                            error.workLocation = 'red';
                            err = true;
                        }

                        if(formData.salaryExpectation ===''){
                            error.salaryExpectation = 'red';
                            err = true;
                        }

                        if(formData.expectedStartingDate ===''){
                            error.expectedStartingDate = 'red';
                            err = true;
                        }else{

                        }



                        let testDay = false;
                        let list = [];
                        dayList.forEach(function(e){
                            if(e.checked){
                                testDay = true;
                                list.push({
                                    "day":e.day,
                                    "type":e.type,
                                    "startHour":e.startHour,
                                    "endHour":e.endHour,
                                    "name":e.name

                                });
                            }
                        });

                        if(testDay){
                            setFormData({
                                ...formData,
                                ["workingDates"]: list
                            });
                            error.workingDates = '';
                        }else{
                            error.workingDates = 'red';
                            err = true;
                        }





                        if(typeof(formData.computerDetail)!=='object'){
                            error.computerDetail = 'red';
                            err = true;
                        }else{
                            if(!formData.computerDetail.hasOwnProperty('availability')){
                                error.computerDetail = 'red';
                                err = true;
                            }else
                            {
                                if(formData.computerDetail.availability ===true){
                                    if(!formData.computerDetail.hasOwnProperty('detail')){
                                        error.computerDetail = 'red';
                                        err = true;
                                    }else{
                                        if(formData.computerDetail.detail.length === 0){
                                            error.computerDetail = 'red';
                                            err = true;
                                        }
                                    }
                                }
                            }

                        }

                        setErrorForm({
                            ...errorForm,
                            computerDetail: error.computerDetail,
                            workingDates: error.workingDates,
                            expectedStartingDate: error.expectedStartingDate,
                            salaryExpectation: error.salaryExpectation,
                            engagementType: error.engagementType,
                        });


                        if(!err){
                            setActiveStep(newStep);

                        }
                        break;
                    case 3:
                        error = {message: ''};
                        if(formData.message.length===0){
                            error.message = 'red';
                            err =true;
                            setErrorForm({
                                ...errorForm,
                                message: 'red',
                            });
                        }else{
                            setErrorForm({
                                ...errorForm,
                                message: '',
                            });
                            setActiveStep(newStep);
                        }

                        break;
                    default:
                        break;
                }
            }
            else{
                setFirstLoad(false);
                setActiveStep(newStep);

            }

        }
    };

    const handleSubmit =() =>{

    }
    const apply =() =>{

        let err = false;

        if(files.length < 4){
            err =true;
        }
        let tf = 0;
        files.forEach(function(e){
            if(['cv','diploma','cover_letter','picture'].includes(e.title.value)){
                tf++;
            }

        });
        if(tf<4){
            err = true;
        }

        let f = new FormData();
        if(err){
            setErrorFormSubmit(t('form_item_file_error_msg_all'));
            setErrorForm({
                ...errorForm,
                files: 'red'
            });
        }else{
            files.forEach((e,i)=>{
                f.append('files',e.file);

            });
            setSendingData(true);
            fetch(uploadUrl, {
                method: "POST",
                body: f,
            }).then((res) => {
                res.json().then((e)=>{
                    console.log(e);
                    let sendData = Object.assign({},formData);
                    sendData.files = [];

                    for(let i =0;i< files.length;i++){
                        sendData.files.push({title:files[i].title.label,file:e.data[i]});
                    }
                    sendData.date = formData.birthdate;
                    fetch(applyUrl, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(sendData),
                    }).then((res) => {

                        if(res.status === 200){
                            setSendingData(false);
                            setActiveStep(4);
                            setTimeout(()=>{
                                window.location.href="/jobs/congratulation";
                            },4000);
                        }else{
                            setSendingData(false);
                            res.json().then((e)=>{
                                setErrorFormSubmit(e.message);
                                setErrorForm({
                                    ...errorForm,
                                    files: 'red'
                                });
                            });
                        }

                    }).catch((err)=>{

                        setSendingData(false);
                        console.log(err);
                    });
                });

            }).catch((error) => {
                setSendingData(false);
                console.log(error)
            });
        }
    }

    const changeCountry =(value) =>{
        if(value===null){
            setCountry('');
        }else{
            setCountry(value);
            updateFormDataField('country',value.value);
        }
    }
    const changeCity =(value) =>{
        if(value===null){
            setCity('');
            setDisplayedNeighborhood([]);
            setNeighborhood('');
            updateFormDataField('city','');
            updateFormDataField('location','');
        }else{
            setCity(value);
            updateFormDataField('city',value.name);
            setDisplayedNeighborhood(allNeighborhood[value.code]);
        }
    }
    const changeNeighborhood =(value) =>{
        if(value===null){
            setNeighborhood('');

            updateFormDataField('location','');
        }else{
            setNeighborhood(value);
            updateFormDataField('location',value.name);
        }
    }

    const removeFile = (e) =>{
        if(files.length>0){
            setRemovedItem(files[e].title)

        }
        let newFiles = files.filter((f,i)=>i!==e);
        setFiles(newFiles);
        updateFormDataField('files',newFiles);


    }

    const displayErrorMsg = (val) =>{
        return val === 'red'?'block':'none';
    }

    const changeWorkType =(value) =>{
        if(value===null){
            setFormData({
                ...formData,
                ["engagementType"]: ''
            });

            updateFormDataField('engagemenType','');
        }else{

            if(value.name==="full time"){
                const nextCounters = dayList.map((c, i) => {


                    if(["remote","on site"].includes(workLocation.name))
                    {
                        c.type = workLocation.name;
                        engagementListTab.forEach(function(e){
                            if(e.name ===  workLocation.name){
                                c.selectedType = e;
                            }
                    });

                    c.isEditableLocation = false;

                    }else{
                        c.isEditableLocation = true;
                    }

                    c.isEditableDay = false;
                    c.isEditableTime = false;
                    c.startHour = "08:00";
                    c.endHour = "17:00";
                    if(c.day<=5){
                        c.checked = true;
                    }

                    return c;
                });

                setTotalHour(40);



            }else{
                const nextCounters = dayList.map((c, i) => {

                    if(["remote","on site"].includes(workLocation.name))
                    {
                        c.type = workLocation.name;
                        engagementListTab.forEach(function(e){
                            if(e.name ===  workLocation.name){
                                c.selectedType = e;
                            }
                        });

                        c.isEditableLocation = false;

                    }else{
                        c.isEditableLocation = true;
                    }

                    c.isEditableDay = true;
                    c.isEditableTime = true;
                    c.checked = false;

                    return c;
                });
                setTotalHour(0);
            }
            setWorktype(value);
            setFormData({
                ...formData,
                ["engagementType"]: value.name
            });
        }
    }
    const changeWorkLocation =(value) =>{
        if(value===null){
            updateFormDataField('workLocation','');
        }else{

            const nextCounters = dayList.map((c, i) => {


                if(["on site","remote"].includes(value.name)){
                    c.isEditableLocation = false;
                    c.type = value.name;
                    c.selectedType = value;
                    setWorkLocation(value);
                }else{
                    c.isEditableLocation = true;
                    c.type = engagementList[2].name;
                    c.selectedType = engagementList[2];
                    setWorkLocation(engagementList[2]);
                }

                return c;
            });
            setDayList(nextCounters);



            updateFormDataField('workLocation',value.name);
        }
    }

    const diffTime = (start, end) => {
        let startD = start.split(":");
        let endD = end.split(":");
        let startDate = new Date(0, 0, 0, startD[0], startD[1], 0);
        let endDate = new Date(0, 0, 0, endD[0], endD[1], 0);
        let diff = endDate.getTime() - startDate.getTime();
        let hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        let minutes = Math.floor(diff / 1000 / 60);

        return (hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes;
    }

    /*const updateTotalHour = (dayList)=>{
        let totalHour = 0;
        dayList.forEach(function(e){

            if(e.checked){
                totalHour += diffTime(e.startHour,e.endHour);

            }

        });
    }*/
    const changeDayStatus =(day) =>{
        const nextCounters = dayList.map((c, i) => {

            if (i === parseInt(day.day-1)) {
                c.checked = !c.checked;

                return c;
            } else {
                // The rest haven't changed
                return c;
            }
        });


        setDayList(nextCounters);

    }
    const changeTimeStartStatus =(day,time) =>{
        const nextCounters = dayList.map((c, i) => {

            if (i === parseInt(day.day-1)) {
                c.startHour = time;
                return c;
            } else {
                // The rest haven't changed
                return c;
            }
        });
        setDayList(nextCounters);

    }

    const changeTimeEndStatus =(day,time) =>{
        const nextCounters = dayList.map((c, i) => {

            if (i === parseInt(day.day-1)) {
                c.endHour = time;
                return c;
            } else {
                // The rest haven't changed
                return c;
            }
        });
        setDayList(nextCounters);

    }
    const changeLocationStatus =(day,value) =>{
        const nextCounters = dayList.map((c, i) => {

            if (i === parseInt(day.day-1)) {
                c.type = value.name;
                c.selectedType = value;
                return c;
            } else {
                // The rest haven't changed
                return c;
            }
        });
        setDayList(nextCounters);

    }

    const changeComputerDetailAvailability = (response)=>{

    }
    const changeComputerDetailInfo = (response)=>{

    }



    return (
        <div>
            <div className='w-screen'>
                <HeaderWithBackground/>
            </div>
            <main>
                <div className="row py-9  mb-10">
                    <div className="col-lg-8 col-xl-8 col-md-24 col-sm-24 ">
                        <div className="h-full w-full overflow-hidden d-lg-block imgFormMobileDiv">
                            <img style={{width: '100%',maxWidth: 550,marginTop: 100}} src={handleBg(activeStep)} alt="" className="imgFormMobile" />
                        </div>
                    </div>
                    <div className="col-lg-16 col-xl-16 col-md-24 col-sm-24">
                        <div className="container" style={{backgroundImage: activeStep === 5?`url(${confeti})`:''}}>
                            <div  className="card card--contact">
                                <div>
                                    <div className="wpcf7 no-js" id="wpcf7-f4365-p3205-o1" lang="en-US" dir="ltr">
                                        <div className="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true" /> <ul /></div>

                                        <div   className="wpcf7-form init wpcf7-acceptance-as-validation div-form"  >
                                            <div className='row'>
                                                <Stepper connectorStateColors={true}
                                                         activeStep={activeStep}
                                                    //activeStep={3}
                                                         connectorStyleConfig={{
                                                             size: 2,
                                                             activeColor: '#152B45', completedColor:'#f9b122' }}
                                                         styleConfig={{ size: 50 }}  style={{width: '100%'}}>
                                                    <Step styleConfig={{labelFontSize: 14,completedBgColor:'#f9b122',size: 50,activeBgColor: '#152B45'}}  completed={activeStep>0} index={0} label={t('form_header_step_one')} />
                                                    <Step styleConfig={{labelFontSize: 14,completedBgColor:'#f9b122',size: 50,activeBgColor: '#152B45'}} completed={activeStep>1} index={1} label={t('form_header_step_two')} />
                                                    <Step styleConfig={{labelFontSize: 14,completedBgColor:'#f9b122',size: 50,activeBgColor: '#152B45'}} completed={activeStep >2} index={2} label={t('form_header_step_five')} />
                                                    <Step styleConfig={{labelFontSize: 14,completedBgColor:'#f9b122',size: 50,activeBgColor: '#152B45'}} completed={activeStep > 3} index={3} label={t('form_header_step_three')} />
                                                    <Step styleConfig={{labelFontSize: 14,completedBgColor:'#f9b122',size: 50,activeBgColor: '#152B45'}} completed={activeStep=== 4} index={4} label={t('form_header_step_four')} />
                                                </Stepper>

                                            </div>
                                            <div className="row" style={{display: activeStep === 0?'block':'none'}} >
                                                <div className="col-24 p-2" >
                                                    <div className="form__input">
                                                                <span className="wpcf7-form-control-wrap" data-name="your-name">
                                                                    <input  placeholder={t('form_item_name')+' *'} onChange={(e)=>updateFormDataField('name',e.target.value)} value={formData.name} style={{borderColor: errorForm.name.length === 0?'#b2b2b2':'red'}} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required floating" autoComplete="off" aria-required="true" aria-invalid="false" type="text" name="your-name" /></span>

                                                        <span className="wpcf7-not-valid-tip" style={{display: errorForm.name.length > 0?'block':'none'}}
                                                              aria-hidden="true">{t('required_field')}</span>
                                                    </div>
                                                    <div className="form__input">
                                                                <span className="wpcf7-form-control-wrap" data-name="your-email">
                                                                    <input placeholder={t('form_item_email') + ' *'} onChange={(e)=>{updateFormDataField('email',e.target.value)}} value={formData.email} style={{borderColor: errorForm.email.length === 0?'#b2b2b2':'red'}} size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email floating" autoComplete="off" aria-required="true" aria-invalid="false" type="email" name="your-email" /></span>
                                                        <span className="wpcf7-not-valid-tip" style={{display: errorForm.email.length > 0?'block':'none'}}
                                                              aria-hidden="true">{t('invalid_email')}</span>
                                                    </div>
                                                    <div className="form__input">
                                                                <span className="wpcf7-form-control-wrap" data-name="your-birthdate">
                                                                    <input placeholder={t('form_item_birthdate') + ' *'} onChange={(e)=>{updateFormDataField('birthdate',e.target.value)}} value={formData.birthdate} style={{borderColor: errorForm.birthdate.length === 0?'#b2b2b2':'red'}} size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email floating" autoComplete="off" aria-required="true" aria-invalid="false" onFocus={(e) => e.target.type = 'date'} type="text" name="your-birthdate" /></span>
                                                        <span className="wpcf7-not-valid-tip" style={{display: errorForm.birthdate.length > 0?'block':'none'}}
                                                              aria-hidden="true">{t('invalid_birthdate')}</span>
                                                    </div>

                                                    <div className="form__input">
                                                        <span className="wpcf7-form-control-wrap" data-name="your-tel">
                                                        <PhoneInput
                                                            country={'cm'}
                                                            value={formData.phone.value}
                                                            inputClass="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel floating"
                                                            placeholder={t('form_item_phone')+ ' *'}
                                                            onChange={(value,country, event,formattedValue)=>{updateFormDataField('phone',{ value:value,country:country,format:formattedValue})}}


                                                            inputStyle={{
                                                                borderColor: errorForm.phone.length ==0 ?'#b2b2b2':'red'
                                                            }}
                                                            inputProps={{
                                                                name: 'phone',
                                                                required: true,
                                                                autoFocus: false
                                                            }}
                                                        />
                                                        </span>
                                                        <br/>
                                                        <span className="wpcf7-not-valid-tip" style={{display: errorForm.phone.length > 0?'block':'none'}}
                                                              aria-hidden="true">{t('phone_error')}</span>
                                                    </div>



                                                </div>
                                                <div className="col-24">
                                                    <button onClick={()=>handleClick('next')} className="button btn-makiti float-right">{t('go')}</button>

                                                </div>
                                            </div>

                                            <div style={{display: activeStep === 1?'block':'none'}} className="row" >
                                                <div className="col-md-24 mb-3">
                                                    <Select

                                                        noOptionsMessage={()=>t('no_option_available')}
                                                        onChange={(choice) => {changeCountry(choice)}}
                                                        value={country}
                                                        components={{
                                                            IndicatorSeparator: () => null,
                                                        }}
                                                        isClearable={false}
                                                        placeholder={t('country')}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#f8b020',
                                                                primary: '#f8b020',
                                                            },
                                                        })} isSearchable={true} options={countries} />
                                                </div>
                                                <div className="col-md-24 mb-3">
                                                    <Select
                                                        styles={{ control: (base) => ({
                                                                ...base,
                                                                border: errorForm.city.length>0?"1px solid red":"1px solid #cccccc",
                                                                boxShadow: 'none'
                                                            }) }}
                                                        getOptionLabel={option => option.name}
                                                        getOptionValue={option => option.name}
                                                        noOptionsMessage={()=>t('no_option_available')}
                                                        onChange={(choice) => changeCity(choice)}
                                                        value={city}
                                                        components={{
                                                            IndicatorSeparator: () => null,
                                                        }}
                                                        isClearable={true}
                                                        placeholder={t('choose_city')}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#f8b020',
                                                                primary: '#f8b020',
                                                            },
                                                        })} isSearchable={true} options={cities} />
                                                </div>
                                                <div className="col-md-24 mb-3">
                                                    <Select
                                                        styles={{ control: (base) => ({
                                                                ...base,
                                                                border: errorForm.location.length>0?"1px solid red":"1px solid #cccccc",
                                                                boxShadow: 'none'
                                                            }) }}
                                                        getOptionLabel={option => option.name}
                                                        getOptionValue={option => option.name}
                                                        noOptionsMessage={()=>t('no_option_available')}
                                                        onChange={(choice) => changeNeighborhood(choice)}
                                                        value={neighborhood}
                                                        components={{
                                                            IndicatorSeparator: () => null,
                                                        }}
                                                        isClearable={true}
                                                        placeholder={t('choose_neighborhood')}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#f8b020',
                                                                primary: '#f8b020',
                                                            },
                                                        })} isSearchable={true} options={displayedNeighborhood} />
                                                </div>
                                                <div className="col-md-24 mb-3">
                                                    <div className="form__input">
                                                                <span className="wpcf7-form-control-wrap" data-name="your-street">
                                                                    <input  placeholder={t('form_item_street')} onChange={(e)=>updateFormDataField('street',e.target.value)} value={formData.street}  size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required floating" autoComplete="off" aria-required="true" aria-invalid="false" type="text" name="your-name" /></span>

                                                    </div>
                                                </div>


                                                <div className="col-24">
                                                    <button onClick={()=>handleClick('next')} className="button btn-makiti float-right">{t('go')}</button>
                                                    <button onClick={()=>handleClick('back')} className="button btn-makiti float-left">{t('back')}</button>

                                                </div>
                                            </div>

                                            <div style={{display: activeStep === 2?'block':'none'}} className="row" >
                                                <div className="col-md-24">
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <Select
                                                                getOptionLabel={option => option.label}
                                                                getOptionValue={option => option.name}
                                                                styles={{ control: (base) => ({
                                                                        ...base,
                                                                        border: errorForm.engagementType.length>0?"1px solid red":"1px solid #cccccc",
                                                                        boxShadow: 'none'
                                                                    }) }}
                                                                noOptionsMessage={()=>t('no_option_available')}
                                                                onChange={(choice) => {changeWorkType(choice)}}
                                                                value={worktype}
                                                                components={{
                                                                    IndicatorSeparator: () => null,
                                                                }}
                                                                isClearable={false}
                                                                placeholder={t('engagement_type')}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    borderRadius: 0,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#f8b020',
                                                                        primary: '#f8b020',
                                                                    },
                                                                })} isSearchable={true} options={worktypeList} />
                                                        </div>
                                                        <div className="col-md-12 mb-3">
                                                            <Select
                                                                getOptionLabel={option => option.label}
                                                                getOptionValue={option => option.name}
                                                                styles={{ control: (base) => ({
                                                                        ...base,
                                                                        border: errorForm.workLocation.length>0?"1px solid red":"1px solid #cccccc",
                                                                        boxShadow: 'none'
                                                                    }) }}
                                                                noOptionsMessage={()=>t('no_option_available')}
                                                                onChange={(choice) => {changeWorkLocation(choice)}}
                                                                value={workLocation}
                                                                components={{
                                                                    IndicatorSeparator: () => null,
                                                                }}
                                                                isClearable={false}
                                                                placeholder={t('work_location')}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    borderRadius: 0,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#f8b020',
                                                                        primary: '#f8b020',
                                                                    },
                                                                })} isSearchable={true} options={engagementList} />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-24 mb-3" style={{display: worktype!==''&&workLocation!=='' ?'block':'none',overflowX:'scroll'}}>
                                                    <div className="row" >
                                                        <div className="col-md-24 mb-1">
                                                            <table className="styled-table time-table "
                                                                   style={{
                                                                       borderStyle:errorForm.workingDates ===''?'none':'solid',
                                                                       borderWidth:errorForm.workingDates ===''?0:2,
                                                                       borderColor:errorForm.workingDates ==='transparent'?0:'red',
                                                            }} >
                                                                <thead>
                                                                    <tr>
                                                                        <th>{t('day')}</th>
                                                                        <th>{t('start_hour')}</th>
                                                                        <th>{t('end_hour')}</th>
                                                                        <th>{t('location')}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                        {dayList.map((day,i) => (
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className="checkbox-wrapper-28">
                                                                        <input
                                                                            disabled={!day.isEditableDay}
                                                                            name={day.name}
                                                                            checked={day.checked}
                                                                            onChange={(e)=>changeDayStatus(day)}
                                                                            id={'tmp-'+day.name} type="checkbox"
                                                                            className="promoted-input-checkbox"/>
                                                                        <svg>
                                                                            <use xlinkHref={'#checkmark-'+day.name}/>
                                                                        </svg>
                                                                        <label htmlFor={'tmp-'+day.name}>
                                                                            {day.name}
                                                                        </label>
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                             style={{display: day.checked===true?'block':'none'}}>
                                                                            <symbol id={'checkmark-'+day.name} viewBox="0 0 24 24">
                                                                                <path strokeLinecap="round"
                                                                                      strokeMiterlimit="10" fill="none"
                                                                                      d="M22.9 3.7l-15.2 16.6-6.6-7.1">
                                                                                </path>
                                                                            </symbol>
                                                                        </svg>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        readOnly={!day.isEditableTime}
                                                                        name={'time'+day.name}
                                                                        onChange={(e)=>changeTimeStartStatus(day,e.target.value)}
                                                                        id={'time-'+day.name} type="time"
                                                                        value={day.startHour}
                                                                        className=""/>

                                                                </td>
                                                                <td>
                                                                    <input
                                                                        readOnly={!day.isEditableTime}
                                                                        name={'time'+day.name}
                                                                        onChange={(e)=>changeTimeEndStatus(day,e.target.value)}
                                                                        id={'time-'+day.name} type="time"
                                                                        value={day.endHour}
                                                                        className=""/>
                                                                </td>
                                                                <td>
                                                                    <Select
                                                                        isDisabled={!day.isEditableLocation}
                                                                        getOptionLabel={option => option.label}
                                                                        getOptionValue={option => option.name}
                                                                        styles={{ control: (base) => ({
                                                                                ...base,
                                                                                //border: errorForm.engagementType.length>0?"1px solid red":"1px solid #cccccc",
                                                                                boxShadow: 'none'
                                                                            }) }}
                                                                        noOptionsMessage={()=>t('no_option_available')}
                                                                        onChange={(choice) => {changeLocationStatus(day,choice)}}
                                                                        value={day.selectedType}
                                                                        components={{
                                                                            IndicatorSeparator: () => null,
                                                                        }}
                                                                        isClearable={false}
                                                                        placeholder={t('work_location')}
                                                                        theme={(theme) => ({
                                                                            ...theme,
                                                                            borderRadius: 0,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary25: '#f8b020',
                                                                                primary: '#f8b020',
                                                                            },
                                                                        })} isSearchable={true} options={engagementListTab} />

                                                                </td>
                                                            </tr>
                                                        ))}



                                                        </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="col-md-24 mb-1">
                                                            <div className="form__input">
                                                                <span className="wpcf7-form-control-wrap" data-name="expected_salary">
                                                                    <CurrencyInput
                                                                        id="input-example-currency"
                                                                        name="input-name"
                                                                        placeholder={t('expected_salary')+' *'}
                                                                        style={{borderColor: errorForm.salaryExpectation.length === 0?'#b2b2b2':'red'}}
                                                                        /*defaultValue={1000}*/
                                                                        allowNegativeValue={false}
                                                                        decimalsLimit={3}
                                                                        suffix={' FCFA'}
                                                                        value={formData.salaryExpectation}
                                                                        onValueChange={(value, name) => updateFormDataField('salaryExpectation',value)}
                                                                    />
                                                                </span>
                                                                <span className="wpcf7-not-valid-tip" style={{display: errorForm.salaryExpectation.length > 0?'block':'none'}}
                                                                      aria-hidden="true">{t('required_field')}</span>
                                                            </div>
                                                            <div className="form__input">
                                                                <span className="wpcf7-form-control-wrap" data-name="expectedStartingDate">
                                                                    <input placeholder={t('expected_starting_date') + ' *'} onChange={(e)=>{updateFormDataField('expectedStartingDate',e.target.value)}} value={formData.expectedStartingDate} style={{borderColor: errorForm.expectedStartingDate.length === 0?'#b2b2b2':'red'}} size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email floating" autoComplete="off" aria-required="true" aria-invalid="false" onFocus={(e) => e.target.type = 'date'} type="text" name="expectedStartingDate" /></span>
                                                                <span className="wpcf7-not-valid-tip" style={{display: errorForm.expectedStartingDate.length > 0?'block':'none'}}
                                                                      aria-hidden="true">{t('invalid_expected_date')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-24 mb-3">
                                                            <input name="comp-det" id="comp-det" type="checkbox" className="switch"
                                                                   checked={formData.computerDetail.availability}
                                                                   onChange={(e)=>updateFormDataField('computerDetailAvailability',!formData.computerDetail.availability)}/>
                                                            <label htmlFor="comp-det">{t('computer_detail')} ?</label>
                                                        </div>
                                                        <div className="col-md-24 mb-3" style={{display: formData.computerDetail.availability?'block':'none'}}>
                                                            <textarea placeholder={t('computer_detail_info')} style={{borderColor: errorForm.computerDetail.length === 0?'#b2b2b2':'red'}} rows={5} value={formData.computerDetail.detail} onChange={(e)=>updateFormDataField('computerDetailInfo',e.target.value)} name="computerDetailInfo" className="text-area wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email floating" />
                                                        </div>

                                                    </div>

                                                </div>

                                                <div className="col-24">
                                                    <button onClick={()=>handleClick('next')} className="button btn-makiti float-right">{t('go')}</button>
                                                    <button onClick={()=>handleClick('back')} className="button btn-makiti float-left">{t('back')}</button>

                                                </div>
                                            </div>

                                            <div style={{display: activeStep === 3?'block':'none'}} className="row">
                                                <h4 style={{textAlign:'center',fontSize: '1.1em',marginTop: 20}}>{t('required_document')} : {t('file_cv')} , {t('file_diploma')} , {t('file_cover_letter')} , {t('file_picture')}  </h4>
                                                <div className="col-24 -space-y-px rounded-md shadow-sm mt-4">
                                                    <Upload
                                                        color={errorForm.files}
                                                        files={files}
                                                        setFiles={setFiles}
                                                        sendDataToForm={updateFormDataField}
                                                        removedItem={removedItem}
                                                        resetUploadFormError={resetUploadFormError}
                                                    />
                                                    <p className='' style={{border: '1px solid red',display: errorForm.files.length>0?'flex':'none'}}>
                            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                            {errorFormSubmit}
                        </span>
                                                    </p>

                                                </div>

                                                <br/>
                                                <div className="flex">
                                                    {files.map((f,i)=>(<div key={i} style={{alignItems: 'baseline', flexDirection: 'row',display:'flex'}} className='mt-4 outline-dashed outline-gray-300 w-auto  m-auto  items-center flex-col'>
                                                        <button className="button " style={{width:50, marginRight: 4, display:sendingData===false?'block':'none'}} type="button" onClick={()=>removeFile(i)}><svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        </button>
                                                        <span className="mt-2" style={{overflowWrap:'anywhere',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}><strong>{f.title.label} :</strong> {f.file.name}</span>


                                                    </div>))
                                                    }
                                                </div>


                                                <div className="col-24 mak">
                                                    <button disabled={sendingData}  onClick={()=>apply()} className="button btn-makiti float-right"> {t('send')}
                                                        <img style={{width:15,display: sendingData ===true?'inline':'none'}} src={loader}/></button>
                                                    <button style={{display: sendingData ===false?'block':'none'}} disabled={sendingData} onClick={()=>handleClick('back')} className="button btn-makiti float-left">{t('back')}</button>

                                                </div>


                                            </div>

                                            <div style={{display: activeStep === 4 &&
                                                errorForm.email.length===0 &&
                                                errorForm.name.length===0 &&
                                                errorForm.phone.length===0 &&
                                                errorForm.engagementType.length===0 &&
                                                errorForm.salaryExpectation.length===0 &&
                                                errorForm.expectedStartingDate.length===0 &&
                                                errorForm.workingDates.length===0 &&
                                                errorForm.computerDetail.length===0 &&
                                                errorForm.message.length===0 ?'block':'none'}} className="row mt-3" >

                                                <div className="col-24 aos-init aos-animate" data-aos="fade-right">
                                                    <h2 className="p-typ__title font-weight-semibold" style={{marginBottom: '28px',fontSize: 24}}>{t('document_sent')}</h2>


                                                </div>

                                            </div>
                                            <div className="wpcf7-response-output" aria-hidden="true" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
}

export default Form;
