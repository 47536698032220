import React from 'react'
import JoinUs from '../Components/JoinUs'
import "tippy.js/dist/tippy.css";
import flyer2 from "../Img/jobs_angular_dev-01.jpeg";
import '../Pages/Footer.css'
import HeaderTransparent from "../CoreComponent/HeaderTransparent";
import Footer from "../Components/Footer";
import Avantages from "../Components/Avantages";
import HeaderWithBackground from "../CoreComponent/HeaderWithBackground";

import {useTranslation} from "react-i18next";

function AngularDev(props) {
    const { t,i18n } = useTranslation();
    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };
    scrollToTop();
    return (

        <div>
            <div className="w-screen ">
                <HeaderWithBackground title="Jobs" />
            </div>
            <main>
                <div className=" container-fluid position-relative mb-4" style={{marginTop:100}}>
                    <div className="row  h-100" style={{display: 'flex',justifyContent:'center'}}>

                        <h2 className=" mb-2 mt-4 mt-lg-0">{t('developer_angular')}</h2>
                    </div>
                    <div className="row  h-100">

                        <div className="col-lg-12 mb-4">
                            <div className="modal-works__page mt-4 mt-lg-0 js-dragscroll" style={{background: '#5a67791c'}}>
                                <img width={1400} height={4716} src={flyer2} className="attachment-full size-full" alt="" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-12 mb-2">
                            <div className="   ">
                                <p className=" font-weight-light mb-1" style={{fontSize: 16,lineHeight: 1.4}}>
                                    {t('angular_description')}
                                </p>
                                <p className="mb-2 font-weight-light " style={{fontSize: 16,lineHeight: 2}} >
                                    {t('ang_desc2')}
                                </p>

                                <p className="font-weight-semibold font-size-m mb-1">{t('competences')}</p>
                                <p className="mb-1" style={{lineHeight: 2}}>
                                    {t('competence_long_description')}
                                </p>

                                <ul className="list-inline list--dots ">
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('ang1')}</li>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('ang2')}</li>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('ang3')}</li>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('ang4')}</li>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('ang5')}</li>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('list_competence6')}</li>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('list_competence7')}</li>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('list_competence8')}</li>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('list_competence9')}</li>
                                        </div>
                                    </div>
                                </ul>
                                <p className="mb-4" style={{lineHeight: 2}}>
                                    {t('competence_end')}
                                </p>
                                <p className="font-weight-semibold font-size-m mb-1">{t('aptitudes')}</p>
                                <ul className="list-inline list--dots mb-3">
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('ponctualtie')} </li>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('respect_des_delais')} </li>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('autonomie')} </li>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-24">
                                            <li className="">{t('maitrise_langue')} </li>
                                        </div>
                                    </div>
                                </ul>

                            </div>
                        </div>
                        <div className="col-lg-12 mb-2">
                        </div>
                    </div>
                    <div className="row flex mt-4" style={{justifyContent: 'center'}}>
                        <div className="">
                            <p className="font-weight-semibold font-size-m mb-1" style={{textAlign: 'center'}}>{t('nos_avantages')}</p>
                            <Avantages/>
                            <JoinUs job={"Développeur Fullstack"} />
                        </div>

                    </div>
                </div>


            </main>

            <Footer style={{fontSize: 14, position: props.position ===undefined?'fixed':'relative',width:'100%',bottom: 0}} />

        </div>
    )
}

export default AngularDev;
