import React from "react";

export default function ServiceItem(props){
    return (<div className="col-md-12 col-lg-8 col-sm-24 col-xl-6">
        <div  className="card card--extend card-service text-center pb-3">
            <a className="card--extend__link"href="#" title={props.title} />
            <div style={{height: 250}} className="card--extend__image" data-aos="zoom-in" data-aos-delay={0}>
                <img width={278} height={193} src={props.image} className="attachment-full size-full" alt="" loading="lazy" />
            </div>
            <h4 style={{minHeight: 60}} className="my-1 mx-1 font-size-s font-size-m-sm font-size-m-md-custom font-weight-semibold lh-12">
                {props.title}
            </h4>
            <div  className="card--extend__hidden">
                {props.description}
            </div>
        </div>
    </div>);
}
