import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import HeaderWithBackground from "../CoreComponent/HeaderWithBackground";
import {useTranslation} from "react-i18next";
import { jobUrl, readFile} from "../route";
import Avantages from "../Components/Avantages";
import JoinUs from "../Components/JoinUs";
import Footer from "../Components/Footer";

export default function JobDetail(){
    const { t,i18n } = useTranslation();
    const { title,id } = useParams();
    const[job,setJob]=useState(null);
    const[start,setStart]=useState(true);
    const[jobAvantages,setJobAvantages]=useState([]);
    //const[isFetching,setIsFetching]=useState(true);
    const [sendingData,setSendingData] = useState(true);
    const dataFetchedRef = useRef(false);
    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };
    scrollToTop();

    useEffect(()=>{
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchData();
    },[]);




    const fetchData = () => {
        if(sendingData && job===null) {

            fetch(jobUrl+id, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((res) => {


                if (res.status === 200) {
                    setSendingData(false);
                    res.json().then((e) => {
                        if(e!==null){
                            setJobAvantages(e.jobAdvantages)
                            setJob(e);
                        }


                    });
                } else {
                    setSendingData(false);
                    res.json().then((e) => {
                        console.log(e);
                    });
                }

            }).catch((err) => {

                setSendingData(false);
                console.log(err);
            });
        }
    };


    return(<div>
        <div className="w-screen ">
            <HeaderWithBackground title="Jobs" />
        </div>
        <main>
            {job!==null&&<div className=" container-fluid position-relative mb-4" style={{marginTop:120}}>
                <div   className="">
                    <Link to="/jobs"  >
                        <h2 style={{fontSize:'1.5rem'}}><i className="bi bi-back"></i> {t('back_to_job_list')}</h2>
                    </Link>
                </div>
                <div className="row  " style={{display: 'flex',justifyContent:'center'}}>
                    <h2 className=" mb-2 mt-4 mt-lg-4 mb-4">{job.jobTitle}</h2>

                </div>
                <div className="row" style={{display: 'flex',justifyContent:'center'}}>
                    <JoinUs job={job} />
                </div>
                <div className="row  h-100">

                    <div className="col-lg-12 mb-4">
                        <div className="modal-works__page mt-4 mt-lg-0 js-dragscroll" style={{background: '#5a67791c'}}>
                            {job.hasOwnProperty("image")&&job.image!==null&&<img width={1400} height={4716} src={readFile+job.image.id} className="attachment-full size-full" alt="" loading="lazy" />}

                        </div>
                    </div>
                    <div className="col-lg-12 mb-2">
                        <div className="   ">
                            <p className=" font-weight-light mb-1" style={{fontSize: 16,lineHeight: 1.4}}>
                                {job.jobLongDescription}
                            </p>


                            <p className="mb-2 font-weight-light " style={{fontSize: 16,lineHeight: 2}} >
                                {t('')}
                            </p>

                            <p className="font-weight-semibold font-size-m mb-1" style={{textTransform:'uppercase'}}>{t('competences')}</p>
                            <p className="mb-1" style={{lineHeight: 2}}>
                                {job.jobSkillsIntro}
                            </p>

                            <ul className="list-inline list--dots ">
                                {job.jobSkills.map((f,i)=>(<div className="row" key={i.toString()+'-skill'} id={i.toString()+'-skill'}>
                                    <div className="col-24">
                                        <li className="">{f}</li>
                                    </div>
                                </div>))}
                            </ul>
                            <p className="mb-2" style={{lineHeight: 2}}>
                                {t('competence_end')}
                            </p>
                            {job.jobAbilities.length>0&&<><p className="font-weight-semibold font-size-m mb-1">{t('aptitudes')}</p>
                            <ul className=" list-inline list--dots mt-1">
                                {job.jobAbilities.map((f,i)=>(<div key={i.toString()+'-abil'} className="row" id={i.toString()+'-abil'}>
                                    <div className="col-24">
                                        <li className="lst">{f}</li>
                                    </div>
                                </div>))}
                            </ul></>}

                            {job.jobPersonnalQualities.length> 0 &&<><p className="font-weight-semibold font-size-m mb-1">{t('personal_qualities')}</p>
                                <ul className=" list-inline list--dots mt-1">
                                    {job.jobPersonnalQualities.map((f,i)=>(<div key={i.toString()+"jobab"} className="row" id={i.toString()+"jobab"}>
                                        <div className="col-24">
                                            <li className="lst">{f}</li>
                                        </div>
                                    </div>))}
                                </ul></>}

                        </div>
                    </div>

                </div>
                <div className="row flex mt-4" style={{justifyContent: 'center'}}>
                    <div className="">
                        <p className="font-weight-semibold font-size-m mb-1" style={{textAlign: 'center'}}>{t('nos_avantages')}</p>
                        <Avantages data={jobAvantages}/>
                        <JoinUs job={job} />
                    </div>

                </div>
            </div>}
            {sendingData&&(<div style={{marginTop:100,minHeight:800}}> <svg
                xmlns="http://www.w3.org/2000/svg"
                width={224}
                height={224}
                preserveAspectRatio="xMidYMid"
                style={{
                    margin: "auto",
                    background: "transparent",
                    display: "block",
                    shapeRendering: "auto",
                }}
                viewBox="0 0 100 100"
            >
                <circle cx={50} cy={23} r={13} fill="#f8b020">
                    <animate
                        attributeName="cy"
                        calcMode="spline"
                        dur="0.8695652173913042s"
                        keySplines="0.45 0 0.9 0.55;0 0.45 0.55 0.9"
                        keyTimes="0;0.5;1"
                        repeatCount="indefinite"
                        values="23;77;23"
                    />
                </circle>
            </svg></div>)}


        </main>


        <Footer style={{fontSize: 14, position: 'relative',width:'100%',bottom: 0}} />

    </div>);

}
