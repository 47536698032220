import React, {useEffect, useState} from 'react'
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import new01 from "../images/Avantages_icons/finance.png"
import new02 from "../images/Avantages_icons/work_hour.png";
import new03 from "../images/Avantages_icons/internet.png";
import new04 from "../images/Avantages_icons/international_assignements.png";
import new05 from "../images/Avantages_icons/transport.png";
import new06 from "../images/Avantages_icons/dynamic_team.png";
import new07 from "../images/Avantages_icons/continuos_capacity.png";
import new08 from "../images/Avantages_icons/ration.png";

import {useTranslation} from "react-i18next";

function Avantages(props) {

    const { t,i18n } = useTranslation();
    const [adv,setAdv]=useState([]);
    useEffect(()=>{
        displayAdvantages();
    },[])

    const displayAdvantages = ()=>
    {
        let all = [];
        props.data.forEach((e)=>{

            switch (e){
                case "finance":
                    all.push({
                        label: t('motivating_remuneration'),
                        image: new01
                    })
                    break;
                case "work":
                    all.push({
                        label: t('adapted_work_hours'),
                        image: new02
                    })
                    break;
                case "internet":
                    all.push( {
                        label: t('internet_available_at_the_premises'),
                        image: new03
                    })
                    break;
                case "international":
                    all.push( {
                        label: t('international_assignments'),
                        image: new04
                    })
                    break;
                case "car":
                    all.push( {
                        label: t('occasional_transportation_of_employees'),
                        image: new05
                    })
                    break;
                case "team":
                    all.push( {
                        label: t('dynamic_and_professional_team'),
                        image: new06
                    })
                    break;
                case "formation":
                    all.push( {
                        label: t('continuous_capacity_building'),
                        image: new07
                    })
                    break;
                case "food":
                    all.push( {
                        label: t('ration_offered_during_breaks_with_a_diversified_weekly_menu'),
                        image: new08
                    })
                    break;
                default:
                    break;

            }


        });
        setAdv(all);
    }
    useEffect (()=>{
        displayAdvantages();
    },[i18n.resolvedLanguage])

    const advantages = [
        {
            label: t('motivating_remuneration'),
            image: new01
        },
        {
            label: t('adapted_work_hours'),
            image: new02
        },
        {
            label: t('internet_available_at_the_premises'),
            image: new03
        },
        {
            label: t('international_assignments'),
            image: new04
        },
        {
            label: t('occasional_transportation_of_employees'),
            image: new05
        },
        {
            label: t('dynamic_and_professional_team'),
            image: new06
        },
        {
            label: t('continuous_capacity_building'),
            image: new07
        },
        {
            label: t('ration_offered_during_breaks_with_a_diversified_weekly_menu'),
            image: new08
        },
    ];
    return (<>
        <div  className="row pb-4 pt-0 pb-xl-5 pt-xl-6 py-xxl-5 pt-xxl-9 justify-content-around align-items-center" >
            {adv.map((advantage,i) => (
                <div key={i} style={{opacity: 1}} className="header-home__logo d-flex align-items-center m-1 m-xxl-0 cursor-pointer">
                    <Tippy content={advantage.label}>
                        <img width={100} height={90} src={advantage.image}  alt={advantage.label} />
                    </Tippy>
                </div>
            ))}
        </div>

    </>)
}

export default Avantages
