
import React, {useEffect, useState} from "react";
import {useLocation, useParams} from 'react-router-dom'
import Form from './../Components/Form';


export default function JobSubmit(){

    const { title,id } = useParams();


    const scrollToTop = () =>{
        window.scrollTo({
            top: 30,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };
    scrollToTop();

    return(<Form title={title} id={id}/>)

}
