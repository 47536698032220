export const baseUrl = "https://pws-be.makiti-group.com/";
//export const baseUrl = "http://localhost:3008/";
export const uploadUrl = baseUrl + "upload/multiple";
export const sendFormUrl = baseUrl + "mail/contact";
export const applyUrl = baseUrl + "candidates";
export const newsletterRegistration = baseUrl + "mail/newsletter-registration";
export const newsletterValidation= baseUrl + "mail/newsletter-validation/";
export const newsletterCancelling= baseUrl + "mail/newsletter-desactivation/";
export const listJobs = baseUrl + "jobs?status=published&pageno=0&limit=20";
export const jobUrl = baseUrl + "jobs/";
export const readFile = baseUrl + "read_file/";
