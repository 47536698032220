import React from "react";

export default function SectionLeftTitleAndRightDescription(props){
    return ( <div className="bg--grey pt-4 pb-3 pt-xl-7 pb-xl-5" data-aos="fade-up" data-aos-duration="2000" style={{zIndex: 1, position: 'relative'}}>
            <div className="container">
                <div className="content-link">
                    <div className="row">
                        <div className="col-lg-7">
                            <h2 className="font-weight-semibold text-center text-lg-left" data-aos="fade-right">
                                {props.title}
                            </h2>
                            <div className="text-center text-lg-left">

                            </div>
                        </div>
                        <div className="col-lg-16 offset-lg-1 pt-1" data-aos="fade-left">
                            <p className="content-link__text font-weight-light ">
                                {props.description}
                            </p>
                            <div style={{height: '20px'}} />

                            <p />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
