import "./upload.css"
import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';




const Upload = ({color,files, setFiles,sendDataToForm,removedItem,resetUploadFormError}) => {



    useEffect(()=>{

        if(removedItem!==null){

            addFileType(removedItem)
        }

    },[removedItem])


    const [currentFile,setCurrentFile] = useState(null);
    const [fileTitle,setFileTitle] = useState("");
    const [isOpen,setIsOpen] = useState(false);
    const { t } = useTranslation();
    const [dragActive, setDragActive] = useState(false);
    const inputRef = React.useRef(null);



    const FILE_TYPES = [{id:1,label:t('file_cv'),value:'cv'},{id:2,label:t('file_diploma'),value:'diploma'},{id:3,label:t('file_certificate'),value:'certificate'},
        {id:4,label:t('file_cover_letter'),value: 'cover_letter'},
        {id:5,label:t('file_picture'),value: 'picture'},
        {id:6,label:t('file_resume'),value: 'resume'},
        {id:7,label:t('file_other'),value: 'other'}
    ];

    const [displayedFiles,setDisplayedFiles]=useState(FILE_TYPES);

    const [fileType,setFileType] = useState(FILE_TYPES[0]);

    const selectFileType =(item) =>{

        setFileType(item);
    }

    const notify = (msg) => toast.warn(msg,{autoClose:10000,closeOnClick:true,pauseOnHover:true,theme:'light'});

    const openBox = () => {
        resetUploadFormError();
        setIsOpen(true);
    }

    const closeBox = () => {
        setIsOpen(false);

           if(currentFile !== null){
               //setFileTitle(enteredName);
               if(fileType!== undefined && fileType.hasOwnProperty("id")){
                   let l = displayedFiles.length;
                   let index = -1;
                   for(let i=0;i<l;i++){
                       if(displayedFiles[i].id === fileType.id){
                           index = i;
                       }
                   }
                   if(index>-1){
                       let tab = displayedFiles;
                       tab.splice(index,1);
                       setDisplayedFiles(tab);
                       setFileType(tab[0]);
                   }
                   setFiles([...files, {title:fileType,file:currentFile}]);
                   sendDataToForm('files',[...files, {title:fileType,file:currentFile}]);
               }


           }
    }

    const addFileType = (ftype)=>{
        let tab  = displayedFiles;
        tab.push(ftype);
        setDisplayedFiles(tab);
        if(fileType === undefined){
            setFileType(tab[0]);
        }

    }

    const handleDragOver = (event) => {
        event.preventDefault();


    };

    const handleDrop = (event) => {
        if(files.length<=6){
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            if(!file) return;
            if(file.size/1024/1024 <2 && ['application/pdf','image/jpeg','image/jpg','image/png'].includes(file.type)){
                file.isUploading = true;

                setCurrentFile(file);
                openBox();



            }
            else{
                notify(t('add_file_error_single'));
            }

        }else{
            notify(t('file_limit_reached'));
        }


    };
    const uploadHandler = (event) => {
        const file = event.target.files[0];
        if(!file) return;

        if(file.size/1024/1024 <2 && ['application/pdf','image/jpeg','image/jpg','image/png'].includes(file.type)){
            file.isUploading = true;

            setCurrentFile(file);
            openBox();



        }
        else{
            console.log('error');
            notify(t('add_file_error_single'));
        }




    }

    const handleClick = (event) => {
        event.target.value = null;

    }



// triggers the input when the button is clicked
    const onButtonClick = () => {
        if(files.length<=6){

            inputRef.current.click();
        }else{
            notify(t('file_limit_reached'));
        }
    };

    return (
        <>
            {isOpen && (
                <>

                    <ReactDialogBox
                        closeBox={()=>closeBox()}

                        headerBackgroundColor='#f9b122'
                        headerTextColor='white'
                        headerHeight='65'
                        closeButtonColor='white'
                        bodyBackgroundColor='white'
                        bodyTextColor='black'
                        bodyHeight='200px'
                        headerText={t('file_title')}
                    >
                        <div>
                            <div className="col-md-24 mb-3">
                                <Select
                                    noOptionsMessage={()=>t('no_option_available')}
                                    onChange={(choice) => {selectFileType(choice)}}
                                    value={fileType}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                    isClearable={false}
                                    placeholder=""
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#f8b020',
                                            primary: '#f8b020',
                                        },
                                    })} isSearchable={false} options={displayedFiles} />
                            </div>
                            <div className="col-md-24">
                                <button className="button btn-makiti float-right" onClick={()=>closeBox()}>{t('add')}</button>
                            </div>
                        </div>
                    </ReactDialogBox>
                </>
            )}
            <ToastContainer />
            <div  onDragOver={handleDragOver}
                 onDrop={handleDrop} style={{outlineColor:"red",border: '1px dashed #f9b122'}} className='file-card flex bg-blue-100 opacity-60 rounded-md outline-dashed outline-gray-300 w-auto h-36 m-auto justify-center items-center flex-col'>

                <button className="upload-button button btn-makiti" style={{borderRadius:0,background:'#ebebeb'}} onClick={onButtonClick}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                    <path
                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
                </svg>{t('upload_file_msg')} (pdf)</button>
                <div className=" file-inputs relative mb-6" >
                    <div className="box" style={{display:"none"}}>
                        <input ref={inputRef} onClick={(event)=>  event.target.value = null} type="file" name="file-1[]" id="file-1" className="input-field" onChange={uploadHandler}/>
                        <label htmlFor="file-1">

                        </label>
                    </div>
                </div>


            </div>

        </>
    )
}

export default Upload
