import React, {Component, useEffect, useRef, useState} from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import logo from "../images/logo_mkt.png";
import logoWhite from "../images/logo_yellow_transp.png";

import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";


const languages = {
    en: { nativeName: 'En' },
    fr: { nativeName: 'Fr' },
    de: { nativeName: 'De' }
};

export default function HeaderTransparent(props){


    const navRef = useRef();
    const btnRef = useRef();



    const [activeLink, setActiveLink] = useState('');
    const [firstloading, setFirstloading] = useState(0);


    useEffect(()=>{
        if(window.location.hash==="#work"){
            setActiveLink("work");
        }
        if(window.location.hash==="#services"){
            setActiveLink("services");
        }

    },[activeLink]);


    const { t,i18n } = useTranslation();

    const returnActiveMenuColor = (menu)=>{
        return activeLink === menu ?"#f9b020":"#000";
    }
    const handleClick = event => {
        let service = event.target.attributes.getNamedItem('href').value;
        switch (service){
            case "/#work":
                setActiveLink("work");
                break;
            case "/#services":
                setActiveLink("services");
                break;
            default:
                break;
        }

        navRef.current.classList.remove(
            'is-open',
        );
        btnRef.current.classList.remove(
            'is-open',
        );
    };
    const handleMenuBtnClick = event =>{

        if(firstloading === 0){
            navRef.current.classList.add(
                'is-open',
            );
            btnRef.current.classList.add(
                'is-open',
            );
        }else{
            if(navRef.current.classList.contains('is-open')){
                navRef.current.classList.remove(
                    'is-open',
                );
                btnRef.current.classList.remove(
                    'is-open',
                );


                console.log('close');
            }
            else{
                navRef.current.classList.add(
                    'is-open',
                );
                btnRef.current.classList.add(
                    'is-open',
                );

                console.log('open');
            }
        }



        setFirstloading(firstloading+1);
    }




    return ( <header  className="header">
        <div className="container container--wide h-100 d-flex align-items-center">
            <div className="d-flex w-100 justify-content-between align-items-center" >
                <a className="header__brand d-flex align-items-center" href="/">
                    <img style={{width: 100,height: 60}}  src={logo} alt="Makiti Group" />
                    <img style={{width: 100,height: 60}}  src={logoWhite} alt="Makiti Group" />
                </a>
                <nav ref={navRef} className='nav--primary js-navbar' id="main-nav">
                    <ul  className="nav--primary__list">
                        <li  className="menu-item menu-item-type-post_type_archive menu-item-object-works menu-item-3239" >
                            <a style={{color:returnActiveMenuColor('services')}} onClick={handleClick} href="/#services">{t('menu_service')}</a>
                        </li>
                        <li  className="menu-item menu-item-type-post_type_archive menu-item-object-works menu-item-3239">
                            <a style={{color:returnActiveMenuColor('work')}}  onClick={handleClick} href="/#work">{t('menu_work')}</a>
                        </li>

                        <li   className=" menu-item menu-item-type-post_type_archive menu-item-object-works menu-item-3239">
                            <Link to="/jobs"  >
                                {t('menu_career')}
                            </Link>
                        </li>

                        <li  className="d-none menu-item menu-item-type-post_type_archive menu-item-object-works menu-item-3239">

                            <a href="/#shop">{t('menu_shop')}</a>
                        </li>
                        <li  className="d-none  menu-item menu-item-type-post_type_archive menu-item-object-works menu-item-3239">
                            <Link to="/aboutus"  >
                                {t('aboutus')}
                            </Link>
                        </li>
                    </ul>
                    <ul className="nav--primary__special d-xl-none">
                        <li className="menu-item">
                            <a onClick={handleClick} className="font-weight-semibold" href="/#contact">{t('btn_get_estimate')}</a>
                        </li>
                    </ul>
                </nav>
                <ul className="nav--primary__special d-none d-xl-block">
                    <li className="menu-item">
                        <a className="text-uppercase" href="/#contact">{t('btn_get_estimate')}</a>
                    </li>
                </ul>



                <button onClick={handleMenuBtnClick}  aria-label="toggle" ref={btnRef} className="nav__toggle js-navbar-toggle"><span /></button>
            </div>
            <div className="nav_lang ml-2">
                <ul className="nav__menu">
                    <li className="nav__menu-item"> <a style={{fontSize: 11,color: '#f9b021',marginRight:50, display: 'flex'}}> <span  className="bi  bi-globe mr-1 ml-1"></span> {i18n.resolvedLanguage}</a>
                        <ul className="nav__submenu">

                            {Object.keys(languages).map((lng) => (i18n.resolvedLanguage!==lng?
                                <li style={{paddingLeft: '30%'}} key={lng} className="nav__submenu-item"><a onClick={() => { i18n.changeLanguage(lng)}}> <span style={{textTransform:'capitalize', color: i18n.resolvedLanguage === lng ? '#000000' : '#152b45ad',fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal'}}>{languages[lng].nativeName}</span>
                                </a></li>:''))}
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </header>);

}
