import React, { Component }  from 'react';

import YAA_logo from "../images/makiti/partner_logo/YAA_logo.png";
import Orange_logo from "../images/makiti/partner_logo/Orange_logo.png";
import Mtn_logo from "../images/makiti/partner_logo/Mtn_logo.png";
import Logo_Merck from "../images/makiti/partner_logo/Logo_Merck.png";
import SFMS_logo from "../images/makiti/partner_logo/SFMS_logo.png";
import PayPal_logo from "../images/makiti/partner_logo/PayPal_logo.png";
import Logo from "../images/makiti/partner_logo/Deutsche_Bahn_AG_Logo.png";
import Iqvia from "../images/makiti/partner_logo/iqvia.png";

export default function PartnersIcon(){
    return (<div className="row pb-4 pt-0 pb-xl-5 pt-xl-4 py-xxl-5  justify-content-around align-items-center" data-aos="fade-up">
         <div className="header-home__logo d-flex align-items-center m-1 m-xxl-0"><img width={179} height={32} src={YAA_logo} alt="" /></div>
         <div className="header-home__logo d-flex align-items-center m-1 m-xxl-0"><img width={137} height={24} src={Orange_logo}  alt="" /></div>
         <div className="header-home__logo d-flex align-items-center m-1 m-xxl-0"><img width={115} height={26} src={Mtn_logo}  alt="" /></div>
         <div className="header-home__logo d-flex align-items-center m-1 m-xxl-0"><img width={147} height={24} src={Logo_Merck}  alt="" /></div>
         <div className="header-home__logo d-flex align-items-center m-1 m-xxl-0"><img width={408} height={480} src={SFMS_logo}  alt="" /></div>
        <div className="header-home__logo d-flex align-items-center m-1 m-xxl-0"><img width={81} height={24} src={PayPal_logo}  alt="" /></div>
        <div className="header-home__logo d-flex align-items-center m-1 m-xxl-0"><img width={400} height={480} src={Logo} alt="" /></div>
        <div className="header-home__logo d-flex align-items-center m-1 m-xxl-0"><img width={800} height={140} src={Iqvia} alt="" /></div>
    </div>);
}
