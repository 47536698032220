import React from "react";

export default function  StatisticItem(props){
    return(<div className="col-md-12 col-xl-12 col-lg-12">
        <div className="d-flex align-items-center mb-3 mb-xxl-0 px-xxl-1">
            <p className="section-technology__number text-primary">{props.number}</p>
            <p  className="section-technology__description ml-1 ml-md-2 lh-12">{props.title}</p>
        </div>
    </div>);
}
