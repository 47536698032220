import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import news from "../images/Newsletter/Newsletter.png";
import loader from './../images/loader.gif';
import {newsletterRegistration} from "../route";
import confeti from './../images/confet.gif';


export default function TalkToUsSection(){
    const { t } = useTranslation();
    const [newsletterError, setNewsletterError] =useState('');
    const [sendingData, setSendingData] =useState(false);
    const [success, setSuccess] =useState(false);
    const [email, setEmail] =useState('');
    const validEmailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

    const subscribe = () => {
        if(!validEmailRegex.test(email)){
            setNewsletterError(t('invalid_email'));
        }else{
            setNewsletterError('');
            setSendingData(true);
            fetch(newsletterRegistration, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({email:email}),
            }).then((res) => {
                if(res.status === 201){
                    setSendingData(false);
                    setSuccess(true);
                    setTimeout(()=>{
                        setEmail('');
                        setSuccess(false);
                    },10000);

                }
                else{
                    setSendingData(false);
                    res.json().then((err)=>{
                        setNewsletterError(err.message.message.toString());
                        setTimeout(()=>{
                            setNewsletterError('');
                        },10000);
                    });

                }

            }).catch((error) => {
                setSendingData(false);
            });
        }



    }



    return ( <section className="overflow-hidden py-4 py-xl-7 overflow-hidden" data-aos-duration="2000" data-aos="fade-up">
        <div className="container" style={{backgroundImage: success === true?`url(${confeti})`:''}}>
            <div className="cta js-particle">
                <div className="row w-100 align-items-center">
                    <div className="col-md-16 col-lg-14">
                        <div className="text-center">
                            <div className="cta__title">{t('talk_to_us_title')}</div>
                            <div className="font-size-m">{t('TalkToUsSection_title_of_paragraph_in_black')}</div>
                            <div className="mt-2 align-content-center">
                                <div className='row' style={{display: 'flex',justifyContent: 'center'}}><span >{t('form_item_email')} *</span></div>
                                <div className="input-group mb-3 form-newsletter ">
                                        <input onChange={(e)=> setEmail(e.target.value)} value={email} placeholder={t('form_item_email')} style={{color: '#000',borderRadius: 0,fontSize: 14}} type="text" className="input-subscribe form-control"
                                               aria-label={t('form_item_email')} aria-describedby="button-addon2" />

                                        <button onClick={()=>subscribe()} disabled={sendingData} style={{display: 'flex', borderRadius: 0}} className="button button--black button--small btn-subscribe" type="button" id="button-addon2">{t('subscribe')}
                                            <img style={{float:'right', width:15,display: sendingData ===true?'inline':'none'}} src={loader}/>
                                        </button>
                                </div>
                            </div>
                            <p style={{color: 'red',minHeight: 100,display: newsletterError!== undefined && newsletterError.length===0?'none':'block'}}>
                                {newsletterError}

                            </p>
                        </div>
                    </div>
                    <div className="d-none d-md-block">
                        <div className="cta__image">
                            <img width={300} height={250}  src={news} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>);
}
