import React from "react";
import {useTranslation} from "react-i18next";
import StatisticItem from "../CoreComponent/StatisticItem";
export default function Statistic(){
    const { t,i18n } = useTranslation();
    return ( <div className="bg--grey pb-5 pb-lg-7" data-aos="fade-up" data-aos-duration="2000">
            <div className="container container--wide">
                <div className="section-technology">
                    <div className="container">
                        <div className="row first-row">
                            <div className="col-lg-8">
                                <p className="section-technology__projects mb-2 mb-md-0 text-center text-lg-left">&gt;25</p>
                            </div>
                            <div className="col-lg-16">
                                <h3 className="section-technology__title font-weight-semibold mb-2 text-center text-lg-left">{t('projects')}</h3>
                                <p className="content-link__text font-weight-light ">{t('statistic')}</p>
                            </div>
                        </div>
                        <div className="row second-row align-items-center justify-content-between mt-3">
                            <StatisticItem number={'8+'} title={t('stat_item_1')} />
                            <StatisticItem number={'25+'} title={t('stat_item_2')} />
                            <StatisticItem number={'15+'} title={t('stat_item_3')} />
                            <StatisticItem number={'800+'} title={t('stat_item_4')} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
