import React from "react";
import {useTranslation} from "react-i18next";
import NumberAndTextItem from "../CoreComponent/NumberAndTextItem";

export default function WhyChooseUsSection(){
    const { t,i18n } = useTranslation();
    return (
        <div>
            <section className="section-margin mb-0 aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
                <div className="container">
                    <div className="content">
                        <h1 className="font-weight-semibold text-center text-lg-left" data-aos="fade-right">
                            {t('why_choose_us')}
                        </h1>

                        <div id="gtx-trans" style={{position: 'absolute', left: '130px', top: '207.719px'}}>
                            <div className="gtx-trans-icon" />
                        </div>
                    </div>
                </div>
            </section>
        <div className="section content-image-list pt-1 " data-aos="fade-right">
            <NumberAndTextItem number={'01'} title={t('why_choose_us_title1')} description={t('why_choose_us_description1')} side={'left'}/>
            <NumberAndTextItem number={'02'} title={t('why_choose_us_title2')} description={t('why_choose_us_description2')} side={'right'}/>
            <NumberAndTextItem number={'03'} title={t('why_choose_us_title3')} description={t('why_choose_us_description3')} side={'left'}/>
            <NumberAndTextItem number={'04'} title={t('why_choose_us_title4')} description={t('why_choose_us_description4')} side={'right'}/>
    </div>
        </div>);
}
