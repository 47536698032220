import React from "react";
import {useTranslation} from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import 'swiper/swiper.css';
import 'swiper/modules/navigation/navigation.min.css';
import mini_portfolio_yaa from "../images/makiti/yaa/portofolio_mini_YAA.jpg";
import mini_portfolio_bg_yaa from "../images/makiti/yaa/portofolio.jpg";
import mini_portfolio_st_express from "../images/makiti/st_express/mini_portfolio_st_express.jpg";
import mini_portfolio_bg_st_express from "../images/makiti/st_express/portfolio_st_express.jpg";
import mini_marine_game from "../images/makiti/marine_game/MINI_PORTFOLIO_game.jpeg";
import openIcon from '../Img/enlarge.svg';
import rightIcon from '../Img/right.png';
import leftIcon from '../Img/left.png';
import JobPortalImage from '../images/job_portal_mini.png';
import JobPortalImageBig from '../images/job_portal_big.jpeg';
import PortfolioItem from "../CoreComponent/PortfolioItem";
import ChildrengameImage from "../images/makiti/marine_game/portfolio_marine_game.jpg"

export default function Portfolio(){
    const { t,i18n } = useTranslation();
    return (<div id="work"><div className="pt-3 pb-3 pt-xl-8 pb-xl-5" data-aos="fade-up" data-aos-duration="2000" style={{zIndex: 1, position: 'relative'}}>
            <div className="container">
                <div className="content-link">
                    <div className="row">
                        <div className="col-lg-7">
                            <h2 className="font-weight-semibold text-center text-lg-left" data-aos="fade-right">
                                Portfolio
                            </h2>

                        </div>
                        <div className="col-lg-16 offset-lg-1 pt-1" data-aos="fade-left">
                            <p className="content-link__text font-weight-light ">
                                {t('portfolio')}
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
            <section className="overflow-hidden slider-works pb-3 pb-xl-4" data-aos="fade-up">
                <div className="position-relative">
                    <div className="">
                        <Swiper
                            breakpoints={{
                                100: {
                                    spaceBetween: 20,
                                    slidesPerView: 1,
                                },
                                // when window width is >= 640px
                                640: {
                                    slidesPerView: 2,
                                },
                                // when window width is >= 768px
                                768: {
                                    slidesPerView: 2,
                                },
                                1024: {
                                    slidesPerView: 3,
                                },
                            }}
                            navigation={true}
                            modules={[Navigation]}
                            spaceBetween={50}
                            slidesPerView={3}
                        >
                            <SwiperSlide>
                                <div className="swiper-slide">
                                <PortfolioItem modal={"modal-yaa"} btnText={t('learn_more')} imageMini={mini_portfolio_yaa} title={t('portfolio_detail_yaa_webshop_title')} description={t('portfolio_detail_yaa_webshop_description')} technologies={[t('portfolio_detail_yaa_webshop_technology')]} />
                                </div>
                                </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                <PortfolioItem modal={"modal-st_express"} btnText={t('learn_more')} imageMini={mini_portfolio_st_express} title={t('portfolio_detail_st_express_webdesign_webshop_title')} description={t('portfolio_detail_st_express_webdesign_webshop_description')} technologies={[t('portfolio_detail_st_express_webdesign_webshop_technology_adobe'),t('portfolio_detail_st_express_webdesign_webshop_technology_photoshop')]} />
                                </div>

                                </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <PortfolioItem modal={"modal-jobs-page"} btnText={t('learn_more')} imageMini={JobPortalImage} title={t('portfolio_detail_jobportal_for_makiti_title')} description={t('portfolio_detail_jobportal_for_makiti_description')} technologies={[t('portfolio_detail_jobportal_for_makiti_technology_React'),t('portfolio_detail_jobportal_for_makiti_technology_Node'),t('portfolio_detail_jobportal_for_makiti_technology_MongoDB')]} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <PortfolioItem modal={"modal-mar"} btnText={t('learn_more')} imageMini={mini_marine_game} title={t('portfolio_detail_children_game_title')} description={t('portfolio_detail_children_game_description')} technologies={[t('portfolio_detail_children_game_technology'),t('portfolio_detail_children_game_technology_Sym'),t('portfolio_detail_children_game_technology_Socket')]} />
                                </div>
                            </SwiperSlide>
                        </Swiper>

                    </div>
                    <div className="slider-works__control" style={{display: 'none'}}>
                        <div className="slider-works__left-gradient" />
                        <div className="slider-works__prev js-slider-works-prev">
                            <img width={18} height={30} src={leftIcon} alt="left arrow" />
                        </div>
                        <div className="slider-works__right-gradient" />
                        <div className="slider-works__next js-slider-works-next">
                            <img width={18} height={30} src={rightIcon} alt="right arrow" />
                        </div>
                    </div>
                </div>


                <div className="modal modal-works js-modal" id="modal-yaa" aria-hidden="true">
                    <div className="modal__overlay" tabIndex={-1}>
                        <div className="modal-works__container position-relative bg--grey" role="dialog" aria-modal="true">
                            <button type="button" className="modal-works__close" data-modal-close="modal-yaa" tabIndex={-1}>
                                <svg viewBox="0 0 34.83 34.83">
                                    <g fill="none" stroke="#000" strokeWidth={4}>
                                        <path d="m1.41 1.41 32 32" data-name="Line 54" />
                                        <path d="m33.41 1.41-32 32" data-name="Line 55" />
                                    </g>
                                </svg>
                            </button>
                            <div className="row no-gutters h-100">
                                <div className="col-lg-12 col-xxl-10">
                                    <div className="px-2 py-lg-4 px-xl-4 modal-works__content">
                                        <h2 className="modal-works__title mb-2 mt-4 mt-lg-0">Young Agro Africa</h2>
                                        <p className="modal-works__subtitle font-weight-light mb-3">{t('portfolio_detail_yaa_webshop_description')}</p>
                                        <p className="mb-3 font-weight-light text-custom-gray">{t('portfolio_detail_yaa_webshop_long_description')}</p>
                                        <p className="font-weight-semibold font-size-m mb-1">{t('technology')}</p>
                                        <ul className="list-inline list--dots mb-2">
                                            <li className="mr-2 pb-1">{t('portfolio_detail_yaa_webshop_technology')}</li>
                                        </ul>
                                        <p className="font-weight-semibold font-size-m mb-1">{t('scope_of_work')}</p>
                                        <ul className="list-inline list--dots mb-3">
                                            <li className="mr-2 pb-1">{t('development')}</li>
                                            <li className="mr-2 pb-1">UX/UI design</li>
                                        </ul>
                                        <div className="mb-3 mb-lg-0">
                                            <div className="d-sm-flex text-sm-left flex-wrap">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xxl-14">
                                    <div className="modal-works__page mt-4 mt-lg-0 js-dragscroll" style={{backgroundImage: 'url(app/uploads/2022/11/bg.jpg)'}}>
                                        <img width={1400} height={4716} src={ mini_portfolio_bg_yaa} className="attachment-full size-full" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal modal-works js-modal" id="modal-st_express" aria-hidden="true">
                    <div className="modal__overlay" tabIndex={-1}>
                        <div className="modal-works__container position-relative bg--grey" role="dialog" aria-modal="true">
                            <button type="button" className="modal-works__close" data-modal-close="modal-st_express" tabIndex={-1}>
                                <svg viewBox="0 0 34.83 34.83">
                                    <g fill="none" stroke="#000" strokeWidth={4}>
                                        <path d="m1.41 1.41 32 32" data-name="Line 54" />
                                        <path d="m33.41 1.41-32 32" data-name="Line 55" />
                                    </g>
                                </svg>
                            </button>
                            <div className="row no-gutters h-100">
                                <div className="col-lg-12 col-xxl-10">
                                    <div className="px-2 py-lg-4 px-xl-4 modal-works__content">
                                        <h2 className="modal-works__title mb-2 mt-4 mt-lg-0">{t('portfolio_detail_st_express_webdesign_webshop_title')}</h2>
                                        <p className="modal-works__subtitle font-weight-light mb-3">{t('portfolio_detail_st_express_webdesign_webshop_description')}</p>
                                        <p className="mb-3 font-weight-light text-custom-gray">{t('portfolio_detail_st_express_webdesign_webshop_long_description')}</p>
                                        <p className="font-weight-semibold font-size-m mb-1">{t('technology')}</p>
                                        <ul className="list-inline list--dots mb-2">
                                            <li className="mr-2 pb-1">{t('portfolio_detail_st_express_webdesign_webshop_technology_adobe')}</li>
                                            <li className="mr-2 pb-1">{t('portfolio_detail_st_express_webdesign_webshop_technology_photoshop')}</li>
                                        </ul>
                                        <p className="font-weight-semibold font-size-m mb-1">{t('scope_of_work')}</p>
                                        <ul className="list-inline list--dots mb-3">
                                            <li className="mr-2 pb-1">{t('content_creation')} </li>
                                            <li className="mr-2 pb-1">{t('content_marketing')}</li>
                                        </ul>
                                        <div className="mb-3 mb-lg-0">
                                            <div className="d-sm-flex text-sm-left flex-wrap">
                                                <div className="mr-2 mb-2">
                                                    <a target="_blank" href="https://assets.adobe.com/id/urn:aaid:sc:EU:b295a311-82dc-4edf-9ca5-f4d3e2fa412d?view=difile" className="button button-arrow d-inline-flex align-items-center w-auto" data-aos="fade-right">
                                                        {t('goto')}
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.995 8"><path data-name="Icon ionic-ios-arrow-round-forward" d="M7.649.148a.544.544 0 0 0 0 .767l2.533 2.537H.537a.542.542 0 0 0 0 1.083h9.637L7.641 7.072a.548.548 0 0 0 0 .767.539.539 0 0 0 .762 0l3.433-3.458a.608.608 0 0 0 .112-.171.517.517 0 0 0 .042-.208.543.543 0 0 0-.154-.379L8.403.165a.531.531 0 0 0-.754-.017Z" /></svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xxl-14">
                                    <div className="modal-works__page mt-4 mt-lg-0 js-dragscroll" style={{backgroundImage: 'url(app/uploads/2022/10/background.png)'}}>
                                        <img width={1400} height={16302} src={mini_portfolio_bg_st_express} className="attachment-full size-full" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal modal-works js-modal" id="modal-jobs-page" aria-hidden="true">
                    <div className="modal__overlay" tabIndex={-1}>
                        <div className="modal-works__container position-relative bg--grey" role="dialog" aria-modal="true">
                            <button type="button" className="modal-works__close" data-modal-close="modal-jobs" tabIndex={-1}>
                                <svg viewBox="0 0 34.83 34.83">
                                    <g fill="none" stroke="#000" strokeWidth={4}>
                                        <path d="m1.41 1.41 32 32" data-name="Line 54" />
                                        <path d="m33.41 1.41-32 32" data-name="Line 55" />
                                    </g>
                                </svg>
                            </button>
                            <div className="row no-gutters h-100">
                                <div className="col-lg-12 col-xxl-10">
                                    <div className="px-2 py-lg-4 px-xl-4 modal-works__content">
                                        <h2 className="modal-works__title mb-2 mt-4 mt-lg-0">{t('portfolio_detail_jobportal_for_makiti_title')}</h2>
                                        <p className="modal-works__subtitle font-weight-light mb-3">{t('portfolio_detail_jobportal_for_makiti_description')}</p>
                                        <p className="mb-3 font-weight-light text-custom-gray">{t('portfolio_detail_jobportal_for_makiti_long_description')}</p>
                                        <p className="font-weight-semibold font-size-m mb-1">{t('technology')}</p>
                                        <ul className="list-inline list--dots mb-2">
                                            <li className="mr-2 pb-1">React</li>
                                            <li className="mr-2 pb-1">NodeJs</li>
                                            <li className="mr-2 pb-1">MongoDB</li>
                                        </ul>
                                        <p className="font-weight-semibold font-size-m mb-1">{t('scope_of_work')}</p>
                                        <ul className="list-inline list--dots mb-3">
                                            <li className="mr-2 pb-1">{t('development')}</li>
                                        </ul>
                                        <div className="mb-3 mb-lg-0">

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xxl-14">
                                    <div className="modal-works__page mt-4 mt-lg-0 js-dragscroll" >
                                        <img width={1400} height={9113} src={JobPortalImageBig} className="attachment-full size-full" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="modal modal-works js-modal" id="modal-mar" aria-hidden="true">
                    <div className="modal__overlay" tabIndex={-1}>
                        <div className="modal-works__container position-relative bg--grey" role="dialog" aria-modal="true">
                            <button type="button" className="modal-works__close" data-modal-close="modal-marine" tabIndex={-1}>
                                <svg viewBox="0 0 34.83 34.83">
                                    <g fill="none" stroke="#000" strokeWidth={4}>
                                        <path d="m1.41 1.41 32 32" data-name="Line 54" />
                                        <path d="m33.41 1.41-32 32" data-name="Line 55" />
                                    </g>
                                </svg>
                            </button>
                            <div className="row no-gutters h-100">
                                <div className="col-lg-12 col-xxl-10">
                                    <div className="px-2 py-lg-4 px-xl-4 modal-works__content">
                                        <h2 className="modal-works__title mb-2 mt-4 mt-lg-0">{t('portfolio_detail_children_game_title')}</h2>
                                        <p className="modal-works__subtitle font-weight-light mb-3">{t('portfolio_detail_children_game_description')}</p>
                                        <p className="mb-3 font-weight-light text-custom-gray">{t('portfolio_detail_children_game_long_description')}</p>
                                        <p className="font-weight-semibold font-size-m mb-1">{t('technology')}</p>
                                        <ul className="list-inline list--dots mb-2">
                                            <li className="mr-2 pb-1">NextJS</li>
                                            <li className="mr-2 pb-1">Symfony</li>
                                            <li className="mr-2 pb-1">Socket IO</li>
                                        </ul>
                                        <p className="font-weight-semibold font-size-m mb-1">{t('scope_of_work')}</p>
                                        <ul className="list-inline list--dots mb-3">
                                            <li className="mr-2 pb-1">{t('development')}</li>
                                        </ul>
                                        <div className="mb-3 mb-lg-0">

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xxl-14">
                                    <div className="modal-works__page mt-4 mt-lg-0 js-dragscroll" >
                                        <img width={1400} height={9113} src={ChildrengameImage} className="attachment-full size-full" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </section>
        </div>

    );
}
