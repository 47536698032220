import React from 'react'
import JoinUs from '../Components/JoinUs'
import JoinUs2 from '../Components/JoinUs2';
import "tippy.js/dist/tippy.css";
import flyer2 from "../Img/jobs_angular_dev-01.jpeg";
import '../Pages/Footer.css'
import HeaderTransparent from "../CoreComponent/HeaderTransparent";
import Footer from "../Components/Footer";
import Avantages from "../Components/Avantages";
import HeaderWithBackground from "../CoreComponent/HeaderWithBackground";
import contactImage from "../images/yvan_axel.png";
import mak from "../Img/mak.png"
import missions from "../Img/missions.png"
import value from "../Img/value.png"
import vision from "../Img/vision.png"
import {useTranslation} from "react-i18next";

function AboutUs(props) {
    const { t,i18n } = useTranslation();
    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };
    scrollToTop();
    return (

        <div>
            <div className="w-screen ">
                <HeaderWithBackground title="AboutUs" />
            </div>
            <main>
                <div className=" container-fluid position-relative mb-4" style={{marginTop:100}}>
                    <div className="row  h-100" style={{display: 'flex',justifyContent:'center'}}>
                    <img  src={mak} className="attachment-full size-full" alt="" loading="lazy" />
                    <div className='col-12' style={{display: 'flex',justifyContent:'center', marginTop:100}}>
                          <h2 className=" mb-2 mt-4 mt-lg-0">{t('aboutus')}</h2>  </div>
                    </div>
                    <div className='row' style={{display: 'flex',justifyContent:'center',margin:50}}>
                        <div  className="font-size-m mt-2 " style={{maxWidth:1000}}>
                             Makiti is a leading Modernization and digital engineering company, headquartered in Yaounde. Makiti provides many services in Digital marketing , Domain and hosting , Ecommerce platform, Individual modularized solution, Mobile application development,interfaces to thirdparties,professional website  and Ux-Ui design services. Created in 2021, the company has since grown to have a presence in Yaounde and Douala. Makiti has partnerships with leading technology providers such as YoungAgroAfrica , Orange, mtn, Merck, SFM System, Paypal, among others, and has build severals projects and recognition for its services. With a focus on innovation and customer-centricity, Makiti aims to help businesses accelerate their modernization and digital transformation journeys.
                        </div>
                    </div>
                    <div style={{margin:100}}>
                    <div className="row  h-100" style={{display: 'flex',justifyContent:'center'}}>
                          <h2 className=" mb-2 mt-4 mt-lg-0">{t('ourteams')}</h2>
                    </div>

                    <div style={{display: 'flex',justifyContent:'center'}}>
                    <div className='row' style={{display: 'flex',justifyContent:'center'}}>

                       <div className='col-sm-12 col-md-12 col-lg-12'>
                             <div className="" data-aos="fade-left" data-aos-delay={200}>
                                <img width={514} height={497} src={contactImage} className="attachment-full size-full" alt="" loading="lazy" />
                            </div>
                            <div className='row' style={{display: 'flex',justifyContent:'start'}}>
                                 <div style={{maxWidth:400}}>
                                                                    Works in IT for over 16 years.
                                    A software engineer with hands-on experience designing highly complex systems from scratch. Technical author of one of the most impactful cyber intelligence systems in the world.
                                    Co-Founder of Stingle Inc. Stingle Photos (USA).
                                    Angel investor with interest in cyber security and aviation projects.
                                    Trustee at Awesome Foundation. Founder and the CEO of The Software Development Company.
                                 </div>
                             </div>
                       </div>
                       <div className='col-sm-12 col-md-12 col-lg-12'>
                             <div className="" data-aos="fade-left" data-aos-delay={200}>
                                <img width={514} height={497} src={contactImage} className="attachment-full size-full" alt="" loading="lazy" />
                            </div>
                            <div className='row' style={{display: 'flex',justifyContent:'start'}}>
                                 <div style={{maxWidth:400}}>
                                                                    Works in IT for over 16 years.
                                    A software engineer with hands-on experience designing highly complex systems from scratch. Technical author of one of the most impactful cyber intelligence systems in the world.
                                    Co-Founder of Stingle Inc. Stingle Photos (USA).
                                    Angel investor with interest in cyber security and aviation projects.
                                    Trustee at Awesome Foundation. Founder and the CEO of The Software Development Company.
                                 </div>
                             </div>
                       </div>

                    </div>
                    </div>
                    <div style={{display: 'flex',justifyContent:'center'}}>
                    <div className='row' style={{display: 'flex',justifyContent:'center'}}>
                       <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className="" data-aos="fade-left" data-aos-delay={200}>
                                            <img width={514} height={497} src={contactImage} className="attachment-full size-full" alt="" loading="lazy" />
                                </div>
                                <div className='row' style={{display: 'flex',justifyContent:'start'}}>
                                 <div style={{maxWidth:400}}>
                                                                    Works in IT for over 16 years.
                                    A software engineer with hands-on experience designing highly complex systems from scratch. Technical author of one of the most impactful cyber intelligence systems in the world.
                                    Co-Founder of Stingle Inc. Stingle Photos (USA).
                                    Angel investor with interest in cyber security and aviation projects.
                                    Trustee at Awesome Foundation. Founder and the CEO of The Software Development Company.
                                 </div>
                             </div>
                       </div>
                       <div className='col-sm-12 col-md-12 col-lg-12'>
                            <div className="" data-aos="fade-left" data-aos-delay={200}>
                                        <img width={514} height={497} src={contactImage} className="attachment-full size-full" alt="" loading="lazy" />
                            </div>
                            <div className='row' style={{display: 'flex',justifyContent:'start'}}>
                                 <div style={{maxWidth:400}}>
                                                                    Works in IT for over 16 years.
                                    A software engineer with hands-on experience designing highly complex systems from scratch. Technical author of one of the most impactful cyber intelligence systems in the world.
                                    Co-Founder of Stingle Inc. Stingle Photos (USA).
                                    Angel investor with interest in cyber security and aviation projects.
                                    Trustee at Awesome Foundation. Founder and the CEO of The Software Development Company.
                                 </div>
                             </div>
                        </div>
                    </div>
                    </div>
                    </div>

                    <div >
                    <div className="row  h-100" style={{display: 'flex',justifyContent:'center'}}>
                          <h2 className=" mb-2 mt-4 mt-lg-0">{t('ourmission')}</h2>
                    </div>

                     </div>
                    <div id="mission">
                        <div className="content-box-md">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="missionItem">
                                        <i className="bi bi-rocket"></i>
                                           <h3>Our Mission</h3>
                                            <hr/>
                                            <p>dhezudgbejfbrzfrfzbri</p>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="missionItem">

                                        <i className="bi bi-eye"></i>

                                           <h3>Our Vision</h3>
                                            <hr/>
                                            <p>dhezudgbejfbrzfrfzbri</p>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="missionItem">

                                        <i className="bi bi-gem"></i>

                                            <h3>Our Value</h3>
                                            <hr/>
                                            <p>dhezudgbejfbrzfrfzbri</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                     </div>
                    <div className="row flex mt-4" style={{justifyContent: 'center'}}>
                        <div className="">
                            <JoinUs2  />
                        </div>

                    </div>



            </main>

            <Footer style={{fontSize: 14, position: props.position ===undefined?'fixed':'relative',width:'100%',bottom: 0}} />

        </div>
    )
}

export default AboutUs;
