import React from "react";
import {useTranslation} from "react-i18next";
import SectionLeftTitleAndRightDescription from "../CoreComponent/SectionLetfTitleAndRighDescription";

import digital2 from './../images/makiti/Service/Marketing digital.png';
import domain2 from './../images/makiti/Service/Domain and Hosting.png';

import ind2 from './../images/makiti/Service/individual solutions.png';
import interf2 from './../images/makiti/Service/Interfaces to Thirdparties.png';
import pro2 from './../images/makiti/Service/Professional_Website.png';
import mobile2 from './../images/makiti/Service/MobileApplication.png';
import ux2 from './../images/makiti/Service/UX_DESIGN.png';
import ecom from './../images/makiti/Service/ecommerce.png';

import ServiceItem from "../CoreComponent/ServiceItem";

import './service.css';

export default function Services(){
    const { t,i18n } = useTranslation();
    return (<>
        <div className="pt-3  mb-xl-n1" data-aos="fade-up" style={{zIndex: 1, position: 'relative'}} id="services">

            <SectionLeftTitleAndRightDescription id={'services'} title={'Services'} description={t('service_description')}/>
        </div>

        <section className="section section--half pb-2" data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="card__list card__list--hoverable">
                    <div className="row ">

                        <ServiceItem image={digital2} title={t('services_detail_digital_marketing')} description={t('services_detail_digital_marketing_description')} />
                        <ServiceItem image={domain2} title={t('services_detail_domain_hosting')} description={t('services_detail_domain_hosting_description')} />
                        <ServiceItem image={ecom} title={t('services_detail_ecommerce_platform')} description={t('services_detail_ecommerce_platform_descritpion')} />
                        <ServiceItem image={ind2} title={t('services_detail_individual_modularized_solutions')} description={t('services_detail_individual_modularized_solutions_description')} />
                        </div>
                    <div className="service ">
                        <ServiceItem image={mobile2} title={t('services_detail_mobile_application_development')} description={t('services_detail_mobile_application_development_description')} />
                        <ServiceItem image={interf2} title={t('services_detail_interfaces_to_thirdparties')} description={t('services_detail_interfaces_to_thirdparties_description')} />
                        <ServiceItem image={pro2} title={t('services_detail_professional_websites')} description={t('services_detail_professional_websites_description')} />
                        <ServiceItem image={ux2} title={t('services_detail_UX/UI-Design')} description={t('services_detail_UX/UI-Design_description')} />
                    </div>
                </div>
            </div>
        </section></>);
}
