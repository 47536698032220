import React from 'react'
import JoinUs from '../Components/JoinUs'
import "tippy.js/dist/tippy.css";
import wr from '../Img/web_redacteur_flyer.jpeg'
import '../Pages/Footer.css'
import HeaderTransparent from "../CoreComponent/HeaderTransparent";
import Footer from "../Components/Footer";
import Avantages from "../Components/Avantages";
import HeaderWithBackground from "../CoreComponent/HeaderWithBackground";
import { t } from 'i18next';
import {useTranslation} from "react-i18next";


function WebRedactor(props) {
    const { t,i18n } = useTranslation();
    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };
    scrollToTop();
    return (
        <div>
            <div className="w-screen ">
                <HeaderWithBackground title="Jobs" />
            </div>
            <main>
                <div className=" container-fluid position-relative mb-4" style={{marginTop:100}}>
                    <div className="row  h-100" style={{display: 'flex',justifyContent:'center'}}>

                        <h2 className=" mb-2 mt-4 mt-lg-0">{t('web_editor')}</h2>
                    </div>
                    <div className="row  h-100">

                        <div className="col-lg-12 mb-4">
                            <div className="modal-works__page mt-4 mt-lg-0 js-dragscroll" style={{background: '#5a67791c'}}>
                                <img width={1400} height={4716} src={wr} className="attachment-full size-full" alt="" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-12 ">

                            <div className=" font-weight-light mb-1" style={{fontSize: 16,lineHeight: 2}}>{t('web_editor_description')}</div>

                            <div className=" font-weight-light mb-1 " style={{fontSize: 16,lineHeight: 2}}>
                                {t('web_editor_desc2')}

                            </div>
                            <p className="mb-1 font-weight-light " style={{fontSize: 16,lineHeight: 2}}>
                                {t('web_editor_desc3')}
                            </p>
                            <p className="font-weight-semibold font-size-m mb-1">{t('competences')}</p>
                            <ul className="list-inline list--dots mb-2">
                                <div className="row">
                                    <div className="col-24">
                                        <li className="">{t('listcompetenceW')} </li>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-24">
                                        <li className="">{t('list_competenceW2')} </li>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-24">
                                        <li className="">{t('list_competenceW3')} </li>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-24">
                                        <li className="">{t('list_competenceW4')} </li>
                                    </div>
                                </div>
                            </ul>
                            <p className="font-weight-semibold font-size-m mb-1">{t('personal_qualities')}</p>
                            <ul className="list-inline list--dots mb-3">
                                <div className="row">
                                    <div className="col-24">
                                        <li className="">{t('list_quality1')} </li>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-24">
                                        <li className="">{t('list_quality2')} </li>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-24">
                                        <li className="">{t('list_quality3')} </li>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-24">
                                        <li className="">{t('list_quality4')} </li>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-24">
                                        <li className="">{t('list_quality5')} </li>
                                    </div>
                                </div>
                            </ul>
                        </div>

                    </div>
                    <div className="row flex mt-4" style={{justifyContent: 'center'}}>
                        <div className="">
                            <p className="font-weight-semibold font-size-m mb-1" style={{textAlign: 'center'}}>{t('nos_avantages')}</p>
                            <Avantages/>
                            <JoinUs  job={"Rédacteur Web"} />
                        </div>

                    </div>
                </div>

            </main>

            <Footer style={{fontSize: 14, position: props.position ===undefined?'fixed':'relative',width:'100%',bottom: 0}} />
        </div>
    )
}

export default WebRedactor;
