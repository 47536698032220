import React, {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import HeaderWithBackground from "../CoreComponent/HeaderWithBackground";
import {useTranslation} from "react-i18next";
import {newsletterCancelling} from "../route";

export default function NewsletterDesactivation(){
    const { t } = useTranslation();
    const { service,id } = useParams();
    const[time,setTime]=useState(10);
    const[start,setStart]=useState(true);
    //const[isFetching,setIsFetching]=useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            if(time>0){
                setTime(time => time - 1);
                if(time === 1){
                    console.log(0);
                    window.history.pushState({}, undefined, "/");
                    window.location.reload();
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);



    if(start===true){
        setStart(false);
        let url = newsletterCancelling+service+'/'+id;
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "GET",
        }).then((res) => {
            if(res.status === 201){
                //setIsFetching(false);
                setTime(time - 1);

            }
            else{
                //setIsFetching(false);
                res.json().then((err)=>{
                    console.log(err);
                });

            }
        }).catch((error) => {
            //setIsFetching(false);
        });
    }


    return(<>
        <div className="w-screen ">
            <HeaderWithBackground background={'white'} title="Jobs" />
        </div>
        <main>
            <div className=" " style={{marginTop: 200}}>
                <div className="mx-auto max-w-3xl py-2 sm:px-6 lg:px-8 mb-8">
                    <h1 className="p-typ__title font-weight-semibold" style={{marginBottom: '28px',textAlign: 'center'}}>{t('newsletter_cancelled')}</h1>
                    <p className="mb-2 font-weight-light text-black_100" style={{textAlign:'center'}}>
                        {t('redirection_in')} : <span>{time} {t('second')}</span>
                    </p>
                </div>
            </div>
        </main>
    </>);

}
